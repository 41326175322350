import React, { useEffect, useState } from "react";
import "./FrontPage.css";
import {
    Add,
    Cable,
    Delete,
    MessageOutlined,
    Search,
    Insights,
    AutoGraph,
    Timeline,
    Visibility,
    VisibilityOff,
    Edit,
} from "@mui/icons-material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
    AddIVR,
    CreateVoiceConfiguration,
    DeleteIvrConfiguration,
    DeleteVoiceBotConfiguration,
    GetIdForTTSPrompt,
    GetIvrListUuid,
    getSMSBroadcastList,
    getWhatsappConfiguration_API,
    PublishChanges,
    ShowExtensionsList,
    showIvrConfigurationList,
    ShowPromptList,
    ShowQueueListForIvr,
    showTelegramBotConfigurationList,
    showViberBotConfigurationList,
    showVoiceBotConfigurationList,
    AddWhatsappConfiguration,
    KnowledgeBaseList,
    DeleteSMSBroadcast,
    DeleteWhatsapp,
    AddTelegramConfiguration,
    AddViberConfiguration,
    AddSMSConfiguration,
    DeleteTelegramConfiguration,
    DeleteViberConfiguration,
    getGroupsListUnassigned,
    getSMSTemplate,
    EditIvr
} from "../utils/api";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Select,
    Slider,
    styled,
    Switch,
    TextField,
    Checkbox,
    ListItemText,
    Tooltip,
    IconButton,
    Chip,
    TextareaAutosize,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import { Space, TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "antd/dist/reset.css";
import { timeZones } from "../FrontPage/timeZones";

dayjs.extend(customParseFormat);

const voiceBotStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    bgcolor: "#F6F6F6",
    borderRadius: "8px",
    outline: "0px",
};

const DeleteVoiceBotStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "13px",
    border: "none",
    p: 3,
    animationName: "none",
    maxHeight: "80vh",
    overflowY: "auto",
    outline: "0px",
};

const ivrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    bgcolor: "#F6F6F6",
    borderRadius: "8px",
    outline: "0px",
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#b2efb3",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const FrontPage = ({accessToken, domainUUID }) => {
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const REACT_APP_API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
    const REACT_APP_API_URL_CASE_CALL_CENTER = process.env.REACT_APP_API_URL_CASE_CALL_CENTER

    const navigate = useNavigate();

    const location = useLocation();
    const { channel } = location.state || {};

    const handleNavigate = (
        configuration_uuid,
        configuration_name,
        channel_name
    ) => {
        navigate("/flow_builder", {
            state: {
                configurationName: configuration_name,
                configurationId: configuration_uuid,
                channel: channel_name,
            },
        });
    };

    // const domainUUID = "50f0827c-d18f-11ee-8379-0a95ea71a99c";
    // const accessToken =
    //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJUZW5hbnQiLCJleHAiOjE3Mjg3MDc0MzMsImlhdCI6MTcyODYyMTAzMywiaXNzIjoiYXV0aC1zZXJ2aWNlIiwibmJmIjoxNzI4NjIxMDMzLCJzdWIiOiI1MGYwODI3Yy1kMThmLTExZWUtODM3OS0wYTk1ZWE3MWE5OWMifQ.fjfJzjB7FQtsZhhxLQyER1A2eNAfVjQt6PLMtS6LKJg";

    const [TabMenu, setTabMenu] = useState("");

    useEffect(() => {
        if (channel === "voiceBot") {
            setTabMenu("Voice Bot");
        } else if (channel === "whatsapp") {
            setTabMenu("WhatsApp Bot");
        } else if (channel === "telegram") {
            setTabMenu("Telegram Bot");
        } else if (channel === "viber") {
            setTabMenu("Viber Bot");
        } else if (channel === "IVR") {
            setTabMenu("IVR");
        } else {
            setTabMenu("Voice Bot");
        }
    }, [channel]);

    const [VoiceBotList, setVoiceBotList] = useState([]);
    const [WhatsappBotList, setWhatsappBotList] = useState([]);
    const [TelegramBotList, setTelegramBotList] = useState([]);
    const [ViberBotList, setViberBotList] = useState([]);
    const [IvrBotList, setIvrBotList] = useState([]);
    const [smsBroadcastList, setSMSBroadcastList] = useState([]);

    const [searchQuery, setSearchQuery] = useState(""); // State for search input


    const fetchVoiceBotFlowsList = async () => {
        const result = await showVoiceBotConfigurationList(accessToken, domainUUID);
        if (result.Status == "1") {
            setVoiceBotList(result.VoiceBotConfiguration);
        }
    };

    const fetchWhatsappFlowsList = async () => {
        const result = await getWhatsappConfiguration_API(accessToken, domainUUID);
        if (result.Status == "1") {
            setWhatsappBotList(result.Whatsapp);
        }
    };

    const fetchTelegramFlowsList = async () => {
        const result = await showTelegramBotConfigurationList(
            accessToken,
            domainUUID
        );
        if (result.Status == "1") {
            setTelegramBotList(result.Telegram);
        }
        else if (result.Status == "0" && result.Msg == "Record Not Found") {
            //when there's no data available, api returns status = "0"
            setTelegramBotList([]);
        }
    };

    const fetchViberBotFlowsList = async () => {
        const result = await showViberBotConfigurationList(accessToken, domainUUID);
        if (result.Status == "1") {
            setViberBotList(result.Viber);
        }
    };

    const fetchIvrFlowsList = async () => {
        const result = await showIvrConfigurationList(accessToken, domainUUID);

        if (result.Status == "1") {
            setIvrBotList(result.IvrList);
            setExitNameList(result.IvrList);
        }
    };

    const fetchSMSBroadcastList = async () => {
        const result = await getSMSBroadcastList(accessToken, domainUUID);
        if (result.status == "1") {
            setSMSBroadcastList(result.sms_broadcasts);
        } else if (result.status == "0") {
            //when there's no data available, api returns status = "0"
            setSMSBroadcastList([]);
        }
    };

    const FetchAllFlowList = () => {
        fetchVoiceBotFlowsList();
        fetchWhatsappFlowsList();
        fetchTelegramFlowsList();
        fetchViberBotFlowsList();
        fetchIvrFlowsList();
        // fetchSMSBroadcastList();
    }

    // This function is for handling the form validations for empty fields
    const ErrorHandlingFunction = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "" || value == "0") ? setError(message) : setError("");
        setInput(value)
    }

    const ErrorHandlingChatLimit = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() === "" || value <= 0 || value > 9999)
            ? setError(message)
            : setError("");

        setInput(value);
    };

    const ErrorHandlingExtension = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "" || value <= 0) ? setError(message) : setError("");
        setInput(value)
    }

    const ErrorHandlingMaxFailures = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "" || value < 0 || value > 20) ? setError(message) : setError("");
        setInput(value)
    }

    const ErrorHandlingMaxTimeout = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "" || value < 0 || value > 20) ? setError(message) : setError("");
        setInput(value)
    }

    const ErrorHandlingTimeout = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "" || value < 1000 || value > 9999) ? setError(message) : setError("");
        setInput(value)
    }

    // This function is for handling the form validations where we can insert 0 as value in select element
    const ErrorHandlingFunction_2 = (e, setInput, setError, message) => {
        let value = e.target.value;

        (value.trim() == "") ? setError(message) : setError("");
        setInput(value)
    }

    // <----------------- FOR VOICE BOT CONFIGURATION ----------------->

    const [CreateVoiceBotConfigurationModal, setCreateVoiceBotConfigurationModal,] = useState(false);
    const [voiceBotConfigurationName, setVoiceBotConfigurationName] = useState("");
    const [voiceBotDescription, setVoiceBotDescription] = useState("");
    const [VoiceBotRetryValue, setVoiceBotRetryValue] = useState(2);
    // Error handling states , 
    const [VoiceBotNameError, setVoiceBotNameError] = useState("");

    const VoiceBotRetryValueArray = [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
    ];

    const HandleCancelButtonVoiceBotModal = () => {
        setCreateVoiceBotConfigurationModal(false);
        setVoiceBotConfigurationName("");
        setVoiceBotDescription("");
        setVoiceBotRetryValue(2);

        // Clearing Error states
        setVoiceBotNameError("")
    }

    const handleAddVoiceBotConfiguration = async () => {
        if (!voiceBotConfigurationName) {
            setVoiceBotNameError("Please enter configuration name");
        } else {
            setVoiceBotNameError("")
        }

        if (!voiceBotConfigurationName) {
            toast.warn("Please fill in all required fields!", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        } else {
            const result = await CreateVoiceConfiguration(
                accessToken,
                domainUUID,
                voiceBotConfigurationName,
                voiceBotDescription,
                VoiceBotRetryValue
            );

            if (result.Status == "1") {
                HandleCancelButtonVoiceBotModal()
                toast.info("Voice Bot Configuration created successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.warn(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }
    };

    // States regarding the DELETE Modal ------------->

    const [DeleteConfigurationModal, setDeleteConfigurationModal] = useState(false);
    const [DeleteConfigurationName, setDeleteConfigurationName] = useState("");
    const [DeleteConfigurationUuid, setDeleteConfigurationUuid] = useState("");

    const HandleDeleteButton = (configuration_uuid, configuration_name) => {
        setDeleteConfigurationModal(true);
        setDeleteConfigurationName(configuration_name);
        setDeleteConfigurationUuid(configuration_uuid);
    };

    const HandleCancelButtonDeleteModal = () => {
        setDeleteConfigurationModal(false);
        setDeleteConfigurationName("");
        setDeleteConfigurationUuid("");
    }

    const DeleteConfiguration = async () => {
        if (TabMenu == "WhatsApp Bot") {
            const result = await DeleteWhatsapp(
                accessToken,
                DeleteConfigurationUuid,
                domainUUID
            );
            if (result.Status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }

        if (TabMenu == "Voice Bot") {
            const result = await DeleteVoiceBotConfiguration(
                accessToken,
                DeleteConfigurationUuid,
                domainUUID
            );

            if (result.Status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }

        if (TabMenu == "Telegram Bot") {
            const result = await DeleteTelegramConfiguration(
                accessToken,
                domainUUID,
                DeleteConfigurationUuid,
            );
            if (result.Status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }

        if (TabMenu == "Viber Bot") {
            const result = await DeleteViberConfiguration(
                accessToken,
                domainUUID,
                DeleteConfigurationUuid,
            );
            if (result.Status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }

        if (TabMenu == "IVR") {
            const result = await DeleteIvrConfiguration(
                accessToken,
                DeleteConfigurationUuid,
                domainUUID
            );
            if (result.Status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }

        if (TabMenu == "SMS") {
            const result = await DeleteSMSBroadcast(
                accessToken,
                DeleteConfigurationUuid,
                domainUUID
            );
            if (result.status == "1") {
                HandleCancelButtonDeleteModal()
                toast.success("Configuration deleted successfully", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else {
                toast.error(result.Msg, {
                    position: "top-right",
                    autoClose: 3000,
                });
            }
        }
    };



    // FOR CREATING THE  OMNI CHANNEL BOT CONFIGURATION  ----------- >

    const [CreateOmniChannelConfigurationModal, setCreateOmniChannelConfigurationModal,] = useState(false);
    const [OmniChannelConfigurationName, setOmniChannelConfigurationName] = useState("");

    const [OmniChannelConfigurationAppId, setOmniChannelConfigurationAppId] = useState("");
    const [ShowOmniChannelConfigurationAppId, setShowOmniChannelConfigurationAppId] = useState(false);

    const [OmniChannelConfigurationChatLimit, setOmniChannelConfigurationChatLimit] = useState("");

    const [OmniChannelConfigurationAppSecret, setOmniChannelConfigurationAppSecret] = useState("");
    const [ShowOmniChannelAppSecret, setShowOmniChannelAppSecret] = useState(false);

    const [OmniChannelConfigurationIntegrationId, setOmniChannelConfigurationIntegrationId] = useState("");
    const [ShowOmniChannelConfigurationIntegrationId, setShowOmniChannelConfigurationIntegrationId] = useState(false);

    const [OmniChannelConfigurationFileSize, setOmniChannelConfigurationFileSize] = useState("0");
    const [OmniChannelConfigurationOutOfServiceMessage, setOmniChannelConfigurationOutOfServiceMessage,] = useState("");
    const [OmniChannelConfigurationWelcomeMessage, setOmniChannelConfigurationWelcomeMessage] = useState("");

    const [OmniChannelConfigurationAiAgentSwitch, setOmniChannelConfigurationAiAgentSwitch] = useState(false);
    const [OmniChannelAIAgentContextList, setOmniChannelAIAgentContextList] = useState([]);
    const [OmniChannelConfigurationAiAgentContextId, setOmniChannelConfigurationAiAgentContextId,] = useState("0");

    const [OmniChannelWorkingDaysArray, setOmniChannelWorkingDaysArray] = useState([]);

    // Error handling states for Omnichannel, 
    const [OmniChannelNameError, setOmniChannelNameError] = useState("")
    const [OmniChannelAppIdError, setOmniChannelAppIdError] = useState("")
    const [OmniChannelChatLimitError, setOmniChannelChatLimitError] = useState("")
    const [OmniChannelAppSecretError, setOmniChannelAppSecretError] = useState("")
    const [OmniChannelIntergrationIdError, setOmniChannelIntergrationIdError] = useState("")
    const [OmniChannelFileSizeError, setOmniChannelFileSizeError] = useState("")
    const [OmniChannelWelcomeMessageError, setOmniChannelWelcomeMessageError] = useState("")
    const [OmniChannelContextIdError, setOmniChannelContextIdError] = useState("")

    useEffect(() => {
        const FetchAIAgentContextList = async () => {
            const result = await KnowledgeBaseList(accessToken, domainUUID);
            if (result.Status == "1") {
                setOmniChannelAIAgentContextList(result.KnowledgeBase);
            }
        };

        if (OmniChannelConfigurationAiAgentSwitch == true) {
            FetchAIAgentContextList();
        }
    }, [OmniChannelConfigurationAiAgentSwitch]);

    const [timeData, setTimeData] = useState({
        Sunday: { start: "09:30:00", end: "18:30:00" },
        Monday: { start: "09:30:00", end: "18:30:00" },
        Tuesday: { start: "09:30:00", end: "18:30:00" },
        Wednesday: { start: "09:30:00", end: "18:30:00" },
        Thursday: { start: "09:30:00", end: "18:30:00" },
        Friday: { start: "09:30:00", end: "18:30:00" },
        Saturday: { start: "09:30:00", end: "18:30:00" },
    });

    const defaultTimeandDate = () => {
        setTimeData({
            Sunday: { start: "09:30:00", end: "18:30:00" },
            Monday: { start: "09:30:00", end: "18:30:00" },
            Tuesday: { start: "09:30:00", end: "18:30:00" },
            Wednesday: { start: "09:30:00", end: "18:30:00" },
            Thursday: { start: "09:30:00", end: "18:30:00" },
            Friday: { start: "09:30:00", end: "18:30:00" },
            Saturday: { start: "09:30:00", end: "18:30:00" },
        });
        ConvertDaysOmniChannelFormat();
    };

    const daysOfWeek = Object.keys(timeData);

    const HandleTimeChange = (day, type) => (time, timeString) => {
        setTimeData((prevState) => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [type]: timeString || "09:30:00", // default to "09:30:00" if cleared
            },
        }));
    };


    const ConvertDaysOmniChannelFormat = () => {
        const formattedData = daysOfWeek.map((day) => ({
            day,
            WorkingHourStartTime: timeData[day].start,
            WorkingHourEndTime: timeData[day].end,
        }));
        setOmniChannelWorkingDaysArray(formattedData);
    };

    useEffect(() => {
        ConvertDaysOmniChannelFormat();
    }, [timeData]);

    const ClearOmniChannelModalStates = () => {
        setCreateOmniChannelConfigurationModal(false);
        setOmniChannelConfigurationName("");
        setOmniChannelConfigurationAppId("");
        setOmniChannelConfigurationChatLimit("");
        setOmniChannelConfigurationAppSecret("");
        setOmniChannelConfigurationIntegrationId("");
        setOmniChannelConfigurationFileSize("0");
        setOmniChannelConfigurationOutOfServiceMessage("");
        setOmniChannelConfigurationWelcomeMessage("");
        setOmniChannelConfigurationAiAgentSwitch(false);
        setOmniChannelConfigurationAiAgentContextId("0");
        defaultTimeandDate();

        // Clearing error states
        setOmniChannelNameError("")
        setOmniChannelAppIdError("")
        setOmniChannelChatLimitError("")
        setOmniChannelAppSecretError("")
        setOmniChannelIntergrationIdError("")
        setOmniChannelFileSizeError("")
        setOmniChannelWelcomeMessageError("")
        setOmniChannelContextIdError("")
    }

    const HandleCreateOmniChannelConfiguration = async () => {

        if (!OmniChannelConfigurationName) {
            setOmniChannelNameError("Please enter configuration name");
        } else {
            setOmniChannelNameError("")
        }

        if (!OmniChannelConfigurationAppId) {
            setOmniChannelAppIdError("Please enter configuration app ID");
        } else {
            setOmniChannelAppIdError("")
        }

        if (!OmniChannelConfigurationChatLimit || OmniChannelConfigurationChatLimit <= 0 || OmniChannelConfigurationChatLimit > 9999) {
            setOmniChannelChatLimitError("Please enter the chat limit in digits between 1-9999");
        } else {
            setOmniChannelChatLimitError("")
        }

        if (!OmniChannelConfigurationAppSecret) {
            setOmniChannelAppSecretError("Please enter the app secret");
        } else {
            setOmniChannelAppSecretError("")
        }

        if (!OmniChannelConfigurationIntegrationId) {
            setOmniChannelIntergrationIdError("Please enter an integration ID");
        } else {
            setOmniChannelIntergrationIdError("")
        }

        if (OmniChannelConfigurationFileSize == "0") {
            setOmniChannelFileSizeError("Please select a suitable file size");
        } else {
            setOmniChannelFileSizeError("")
        }

        if (!OmniChannelConfigurationWelcomeMessage) {
            setOmniChannelWelcomeMessageError("Please enter the welcome message");
        } else {
            setOmniChannelWelcomeMessageError("")
        }

        if (
            OmniChannelConfigurationAiAgentSwitch == true &&
            OmniChannelConfigurationAiAgentContextId == "0"
        ) {
            toast.warn("Please select an Ai agent context.", {
                position: "top-right",
                autoClose: 4000,
            });
            setOmniChannelContextIdError("Please select an Ai agent context")
            return;
        } else {
            setOmniChannelContextIdError("")
        }

        if (
            !OmniChannelConfigurationName ||
            !OmniChannelConfigurationAppId ||
            (!OmniChannelConfigurationChatLimit || OmniChannelConfigurationChatLimit <= 0 || OmniChannelConfigurationChatLimit > 9999) ||
            !OmniChannelConfigurationAppSecret ||
            !OmniChannelConfigurationIntegrationId ||
            OmniChannelConfigurationFileSize == "0" ||
            !OmniChannelConfigurationWelcomeMessage
        ) {
            toast.warn("Please complete mandatory details.", {
                position: "top-right",
                autoClose: 4000,
            });
            return;
        }

        var result;

        if (TabMenu === "WhatsApp Bot") {

            result = await AddWhatsappConfiguration(
                domainUUID,
                accessToken,
                OmniChannelConfigurationName,
                OmniChannelConfigurationAppId,
                OmniChannelConfigurationChatLimit,
                OmniChannelConfigurationAppSecret,
                OmniChannelConfigurationIntegrationId,
                OmniChannelConfigurationOutOfServiceMessage,
                OmniChannelConfigurationFileSize,
                OmniChannelConfigurationAiAgentSwitch,
                OmniChannelConfigurationAiAgentContextId,
                OmniChannelConfigurationWelcomeMessage,
                OmniChannelWorkingDaysArray,
            );
        }

        if (TabMenu === "Telegram Bot") {

            result = await AddTelegramConfiguration(
                domainUUID,
                accessToken,
                OmniChannelConfigurationName,
                OmniChannelConfigurationAppId,
                OmniChannelConfigurationChatLimit,
                OmniChannelConfigurationAppSecret,
                OmniChannelConfigurationIntegrationId,
                OmniChannelConfigurationOutOfServiceMessage,
                OmniChannelConfigurationFileSize,
                OmniChannelConfigurationAiAgentSwitch,
                OmniChannelConfigurationAiAgentContextId,
                OmniChannelConfigurationWelcomeMessage,
                OmniChannelWorkingDaysArray,
            );
        }

        if (TabMenu === "Viber Bot") {

            result = await AddViberConfiguration(
                domainUUID,
                accessToken,
                OmniChannelConfigurationName,
                OmniChannelConfigurationAppId,
                OmniChannelConfigurationChatLimit,
                OmniChannelConfigurationAppSecret,
                OmniChannelConfigurationIntegrationId,
                OmniChannelConfigurationOutOfServiceMessage,
                OmniChannelConfigurationFileSize,
                OmniChannelConfigurationAiAgentSwitch,
                OmniChannelConfigurationAiAgentContextId,
                OmniChannelConfigurationWelcomeMessage,
                OmniChannelWorkingDaysArray,
            );
        }

        if (result.Status == "1") {
            toast.info(result.Msg, {
                position: "top-right",
                autoClose: 4000,
            });
            ClearOmniChannelModalStates();
        }
        else {
            toast.error(result.Msg, {
                position: "top-right",
                autoClose: 4000,
            });
            return;
        }
    };


    // FOR CREATING THE SMS BOT CONFIGURATION

    const [CreateSmsConfigurationModal, setCreateSmsConfigurationModal] = useState(false);
    const [SmsBroadcastName, setSmsBroadcastName] = useState("");
    const [SmsTemplate, setSmsTemplate] = useState("0");
    const [SmsDescription, setSmsDescription] = useState("");

    const [SmsFrequency, setSmsFrequency] = useState("1");
    const SmsFrequenciesList = Array.from({ length: 50 }, (_, i) => i + 1);

    const [SmsTimezone, setSmsTimezone] = useState("0");
    const TimeZonesList = timeZones;

    const [SmsScheduleTime, setSmsScheduleTime] = useState("start_now");

    const [SmsStartDate, setSmsStartDate] = useState(null);
    const [SmsEndDate, setSmsEndDate] = useState(null);
    const [SmsStartTime, setSmsStartTime] = useState(null);
    const [SmsEndTime, setSmsEndTime] = useState(null);

    const [smsReply, setSmsReply] = useState("");
    const [expectedSmsReply, setExpectedSmsReply] = useState("");
    const [repliesList, setRepliesList] = useState([]);

    // Error handling states for SMS configuration, 

    const [SmsNameError, setSmsNameError] = useState("");
    const [SmsTemplateError, setSmsTemplateError] = useState("");
    const [SmsContactListError, setSmsContactListError] = useState("");
    const [SmsTimeZoneError, setSmsTimeZoneError] = useState("");
    const [SmsEndDateTimeError, setSmsEndDateTimeError] = useState("");
    const [SmsReplyListError, setSmsReplyListError] = useState("");
    const [SmsReplyError, setSmsReplyError] = useState("");
    const [SmsExpectedReplyError, setSmsExpectedReplyError] = useState("");

    const handleAddReplies = () => {
        if (!smsReply) {
            toast.warn("Please enter a reply", {
                position: "top-right",
                autoClose: 3000,
            });
            setSmsReplyError("Please enter a reply")
            return
        } else {
            setSmsReplyError("")
        }

        if (!expectedSmsReply) {
            toast.warn("Please enter an expected reply", {
                position: "top-right",
                autoClose: 3000,
            });
            setSmsExpectedReplyError("Please enter an expected reply")
            return
        } else {
            setSmsExpectedReplyError("")
        }

        if (smsReply && expectedSmsReply) {
            setRepliesList((prevList) => [
                ...prevList,
                { reply: smsReply, expectedReply: expectedSmsReply },
            ]);
            setSmsReply("");
            setExpectedSmsReply("");
        }
    };

    const handleDeleteReply = (index) => {
        setRepliesList(repliesList.filter((_, i) => i !== index));
    };

    const setCurrentDateTime = () => {
        const now = dayjs();
        setSmsStartDate(now.format("YYYY-MM-DD")); // Set current date
        setSmsStartTime(now.format("HH:mm:ss")); // Set current time
    };

    useEffect(() => {
        if (SmsScheduleTime === "start_now") {
            setCurrentDateTime();
        }
    }, [SmsScheduleTime]);

    const [groupList, setGroupList] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const HandleCancelButtonSmsModal = () => {
        setCreateSmsConfigurationModal(false);
        setSmsBroadcastName("");
        setSmsDescription("");
        setSelectedGroups([]);
        setSmsTemplate("0");
        setSmsFrequency("1");
        setSmsTimezone("0");
        setSmsScheduleTime("start_now");
        setSmsStartDate(null);
        setSmsEndDate(null);
        setSmsStartTime(null);
        setSmsEndTime(null);
        setRepliesList([]);

        // Clearing error states 
        setSmsNameError("")
        setSmsTemplateError("")
        setSmsContactListError("")
        setSmsTimeZoneError("")
        setSmsEndDateTimeError("")
        setSmsReplyListError("")
        setSmsReplyError("")
        setSmsExpectedReplyError("")
    }

    useEffect(() => {
        const fetchGroups = async () => {
            const result = await getGroupsListUnassigned(accessToken, domainUUID)
            setGroupList(result.GroupList); // Adjust based on your API response structure
        };

        fetchGroups();
    }, []);

    const handleSelectChange = (event, setInput, setError, message) => {
        const {
            target: { value },
        } = event;

        const selectedGroupObjects = groupList.filter((group) =>
            value.includes(group.grp_name)
        );

        if (selectedGroupObjects.length === 0) {
            setError(message);
        } else {
            setError("");
        }

        setInput(selectedGroupObjects);
    };

    const [smstemplateList, setsmsTemplateList] = useState([]);

    const fetchSMSTemplate = async () => {
        const result = await getSMSTemplate(accessToken, domainUUID)
        if (result.status == "1") {

            setsmsTemplateList(result.sms_templates);
        }
        else if (result.status == "0") {
            setsmsTemplateList([]);
        }
    };

    useEffect(() => {
        // fetchSMSTemplate();
    }, []);

    const handleAddSMSConfiguration = async () => {

        if (!SmsBroadcastName) {
            setSmsNameError("Please enter configuration name");
        } else {
            setSmsNameError("")
        }

        if (SmsTemplate == "0") {
            setSmsTemplateError("Please select a SMS template");
        } else {
            setSmsTemplateError("")
        }

        if (selectedGroups.length == 0) {
            setSmsContactListError("Please select at least one contact list")
        } else {
            setSmsContactListError("")
        }

        if (SmsTimezone == "0") {
            setSmsTimeZoneError("Please select a time-zone");
        } else {
            setSmsTimeZoneError("")
        }

        if ((SmsEndTime == null) || (SmsEndDate == null)) {
            setSmsEndDateTimeError("Please select a specific end date & time");
        } else {
            setSmsEndDateTimeError("");
        }

        if (repliesList.length == 0) {
            setSmsReplyListError("Please enter at least one pair of reply and expected reply")
            setSmsReplyError("Please enter a reply")
            setSmsExpectedReplyError("Please enter an expected reply")
        } else {
            setSmsReplyListError("")
        }

        if (
            SmsBroadcastName == "" ||
            SmsTemplate == "0" ||
            selectedGroups.length == 0 ||
            SmsTimezone == "0" ||
            SmsFrequency == "0" ||
            SmsEndDate == null ||
            SmsEndTime == null ||
            repliesList.length == 0
        ) {
            toast.warn("Please Enter all the Required Fields", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        const formattedContactList = JSON.stringify(
            selectedGroups.map((group) => ({
                grp_id: group.grp_id,
                grp_name: group.grp_name,
            }))
        );

        let formattedStartTime = SmsStartTime.slice(0, 5);

        let formattedEndTime = SmsEndTime.slice(0, 5);

        const result = await AddSMSConfiguration(
            accessToken,
            domainUUID,
            SmsBroadcastName,
            formattedContactList,
            SmsTimezone,
            "false",
            SmsScheduleTime,
            SmsStartDate,
            formattedStartTime,
            SmsEndDate,
            formattedEndTime,
            SmsTemplate,
            SmsDescription,
            SmsFrequency
        );

        const buttonList = repliesList.map((item) => item.reply);

        if (result.status == "0") {
            toast.info(result.msg, {
                position: "top-right",
                autoClose: 3000,
            });
        } else if (result.status == "1") {
            const NodeId = result.NodeId;
            const nodes = [
                {
                    id: `${NodeId}`,
                    type: "NewSmsBroadcast",
                    style: { width: "fit-content" },
                    position: { x: Math.random() * 200, y: Math.random * 200 },
                    SmsDescription: SmsDescription,
                    name: SmsBroadcastName,
                    buttonList: buttonList,
                    data: {
                        label: (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "89% 10%",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    gap: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "5px",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <h2
                                        style={{
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            margin: "0px",
                                            textTransform: "capitalize",
                                            color: "#094e6c",
                                            borderBottom: "1px #006d77 solid ",
                                            paddingBottom: "3px",
                                        }}
                                    >
                                        <MessageOutlined
                                            style={{
                                                fontSize: "18px",
                                                color: "#006d77",
                                                marginRight: "8px",
                                            }}
                                        />
                                        SMS
                                    </h2>

                                    <h3
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            margin: "0px",
                                            color: "#161a1d",
                                        }}
                                    >
                                        Name :{" "}
                                        <span style={{ fontWeight: "400" }}>
                                            {" "}
                                            {SmsBroadcastName}
                                        </span>
                                    </h3>

                                    <p
                                        style={{
                                            border: "1px lightgrey solid",
                                            borderRadius: "4px",
                                            padding: "6px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            fontSize: "16px",
                                            fontStyle: "italic",
                                            backgroundColor: "#E9F6E6",
                                            margin: "0px 5px 0px 0px",
                                            textAlign: "justify",
                                            width: "95%",
                                        }}
                                    >
                                        SMS Description :<br /> {SmsDescription}
                                    </p>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "10px",
                                    }}
                                >
                                    {buttonList.map((buttonLabel, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "10px",
                                                        height: "10px",
                                                        backgroundColor: "#006d77",
                                                        borderRadius: "50%",
                                                        marginRight: "8px",
                                                    }}
                                                />
                                                <span>{buttonLabel}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ),
                        buttonList: buttonList,
                    },

                    sourcePosition: "right",
                    targetPosition: "left",
                },
            ];

            const menu_uuid = result.sms_broadcast_uuid;
            const channel = "smsBroadcast";

            const LocalFlow = { nodes, edges };
            localStorage.setItem("flow", JSON.stringify(LocalFlow));
            const flowvalue = localStorage.getItem("flow");

            const PublishChangesResult = await PublishChanges(
                //publish changes api
                accessToken,
                domainUUID,
                menu_uuid,
                flowvalue,
                channel
            );

            if (PublishChangesResult.status == false) {
                toast.info("Error, Please try again", {
                    position: "top-right",
                    autoClose: 3000,
                });
            } else if (PublishChangesResult.status == true) {
                navigate("/flow_builder", {
                    state: {
                        configurationName: SmsBroadcastName,
                        configurationId: menu_uuid,
                        channel: "smsBroadcast",
                        condition: "NewSmsBroadcast",
                    },
                });
            }
        }
    };


    // <----------------- FOR IVR CONFIGURATION ---------------->

    const [CreateIvrConfigurationModal, setCreateIvrConfigurationModal] = useState(false);
    const [IvrName, setIvrName] = useState("");
    const [IvrExtension, setIvrExtension] = useState("");
    const [PromptList, setPromptList] = useState([]);

    const [SelectIvrGreetLong, setSelectIvrGreetLong] = useState("1");
    const [GreetLongMessage, setGreetLongMessage] = useState("");
    const [TtsLongPromptName, setTtsLongPromptName] = useState("");
    const [TtsLongPromptText, setTtsLongPromptText] = useState("");
    const [TtsLongPromptLanguage, setTtsLongPromptLanguage] = useState("1");
    const [TtsLongPromptVoiceType, setTtsLongPromptVoiceType] = useState("1");

    const [SelectIvrGreetShort, setSelectIvrGreetShort] = useState("1");
    const [GreetShortMessage, setGreetShortMessage] = useState("");
    const [TtsShortPromptName, setTTSShortPromptName] = useState("");
    const [TtsShortPromptText, setTTSShortPromptText] = useState("");
    const [TtsShortPromptLanguage, setTtsShortPromptLanguage] = useState("1");
    const [TtsShortPromptVoiceType, setTtsShortPromptVoiceType] = useState("1");

    const [IvrMaxFailures, setIvrMaxFailures] = useState("2");
    const [IvrMaxTimeout, setIvrMaxTimeout] = useState("2");
    const [IvrTimeout, setIvrTimeout] = useState("3000");

    const [IvrSelectIvrExitAction, setIvrSelectIvrExitAction] = useState("1");
    const [ExitNameList, setExitNameList] = useState("");
    const [IvrSelectIvrExitName, setIvrSelectIvrExitName] = useState("0");
    const [IvrStatus, setIvrStatus] = useState(true);
    const [IvrDescription, setIvrDescription] = useState("");
    const [IvrSelectDirectDial, setIvrSelectDirectDial] = useState("false");
    const [IvrSelectRingBack, setIvrSelectRingBack] = useState("${in-ring}");
    const [IvrCallerIdPrefix, setIvrCallerIdPrefix] = useState("");
    const [IvrInvalidSound, setIvrInvalidSound] = useState("0");
    const [IvrExitSound, setIvrExitSound] = useState("0");

    // Error handling states for IVR
    const [IvrNameError, setIvrNameError] = useState("");
    const [IvrExtensionError, setIvrExtensionError] = useState("")
    const [IvrGreetLongError, setIvrGreetLongError] = useState("")
    const [IvrGreetLongMessageError, setIvrGreetLongMessageError] = useState("")
    const [IvrLongNameError, setIvrLongNameError] = useState("")
    const [IvrLongTextError, setIvrLongTextError] = useState("")
    const [IvrLongLanguageError, setIvrLongLanguageError] = useState("")
    const [IvrLongVoiceTypeError, setIvrLongVoiceTypeError] = useState("")
    const [IvrGreetShortError, setIvrGreetShortError] = useState("")
    const [IvrGreetShortMessageError, setIvrGreetShortMessageError] = useState("")
    const [IvrShortNameError, setIvrShortNameError] = useState("")
    const [IvrShortTextError, setIvrShortTextError] = useState("")
    const [IvrShortLanguageError, setIvrShortLanguageError] = useState("")
    const [IvrShortVoiceTypeError, setIvrShortVoiceTypeError] = useState("")
    const [IvrMaxFailuresError, setIvrMaxFailuresError] = useState("")
    const [IvrMaxTimeoutError, setIvrMaxTimeoutError] = useState("")
    const [IvrTimeoutError, setIvrTimeoutError] = useState("")
    const [IvrExitNameError, setIvrExitNameError] = useState("");
    const [IvrInvalidSoundError, setIvrInvalidSoundError] = useState("")
    const [IvrExitSoundError, setIvrExitSoundError] = useState("")

    const getFileLocationById = (Id) => {
        const name = PromptList.find(
            (fileLocation) => fileLocation.pmt_uuid === Id
        );
        return name ? name.file_location : "";
    };

    const getLocationUuid = (locationName, setGreetMessage) => {
        if (locationName.startsWith("say:")) {

            const [prefix, message] = locationName.split(":");
            setGreetMessage(message)
            return prefix;
        } else {
            const fileName = locationName.split('/').pop();
            const matchingPrompt = PromptList.find(
                (prompt) => prompt.file_location.split('/').pop() === fileName
            );
            setGreetMessage("")
            return matchingPrompt ? matchingPrompt.pmt_uuid : locationName;
        }
    };

    const getSoundUuid = (sound) => {
        const matchingPrompt = PromptList.find(
            (prompt) => prompt.file_location.split('/').pop() === sound
        );

        return matchingPrompt ? matchingPrompt.pmt_uuid : sound;
    }

    const HandleCancelButtonIvrModal = () => {
        setCreateIvrConfigurationModal(false);
        setIvrName("");
        setIvrExtension("");
        setSelectIvrGreetLong("1");
        setSelectIvrGreetShort("1");
        setIvrMaxFailures("2");
        setIvrMaxTimeout("2");
        setIvrTimeout("3000");
        setIvrSelectIvrExitAction("1");
        setIvrStatus(true);
        setIvrDescription("");
        setIvrSelectDirectDial("false");
        setIvrSelectRingBack("${in-ring}");
        setIvrCallerIdPrefix("1");
        setIvrInvalidSound("0");
        setIvrExitSound("0");
        setTtsLongPromptName("");
        setTtsLongPromptText("");
        setTtsLongPromptLanguage("1");
        setTtsLongPromptVoiceType("1");
        setTTSShortPromptName("");
        setTTSShortPromptText("");
        setTtsShortPromptLanguage("1");
        setTtsShortPromptVoiceType("1");

        // Clearing error states of IVR
        setIvrNameError("");
        setIvrExtensionError("");
        setIvrGreetLongError("");
        setIvrGreetLongMessageError("");
        setIvrLongNameError("");
        setIvrLongTextError("");
        setIvrLongLanguageError("");
        setIvrLongVoiceTypeError("");
        setIvrGreetShortError("");
        setIvrGreetShortMessageError("");
        setIvrShortNameError("");
        setIvrShortTextError("");
        setIvrShortLanguageError("");
        setIvrShortVoiceTypeError("");
        setIvrMaxFailuresError("");
        setIvrMaxTimeoutError("");
        setIvrTimeoutError("");
        setIvrExitNameError("");
        setIvrInvalidSoundError("");
        setIvrExitSoundError("");

        // Change IsEdit state
        setIsUpdateIvr(false)
    }

    const handleIvrAddEdit = async () => {
        if (IvrName == "") {
            setIvrNameError("Please enter the IVR name")
        } else {
            setIvrNameError("")
        }

        if (IvrExtension == "" || IvrExtension <= 0) {
            setIvrExtensionError("Please enter a valid IVR extension using digits greater than zero")
        } else {
            setIvrExtensionError("")
        }

        if (SelectIvrGreetLong == "1") {
            setIvrGreetLongError("Please select a greet long ")
        } else {
            setIvrGreetLongError("")
        }

        if ((SelectIvrGreetLong == "say" && GreetLongMessage == "")) {
            setIvrGreetLongMessageError("Please enter greet long message")
        } else {
            setIvrGreetLongMessageError("")
        }

        if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptName == "") {
            setIvrLongNameError("Please enter a name for TTS prompt ")
        } else {
            setIvrLongNameError("")
        }

        if (SelectIvrGreetLong == "tts_prompt" && TtsLongPromptText == "") {
            setIvrLongTextError("Please enter a text for TTS prompt ")
        } else {
            setIvrLongTextError("")
        }

        if (SelectIvrGreetLong == "tts_prompt" && (TtsLongPromptLanguage == "1")) {
            setIvrLongLanguageError("Please select a language ")
        } else {
            setIvrLongLanguageError("")
        }

        if (SelectIvrGreetLong == "tts_prompt" && (TtsLongPromptVoiceType == "1")) {
            setIvrLongVoiceTypeError("Please select a voice type")
        } else {
            setIvrLongVoiceTypeError("")
        }

        if (SelectIvrGreetShort == "1") {
            setIvrGreetShortError("Please select a greet short ")
        } else {
            setIvrGreetShortError("")
        }

        if ((SelectIvrGreetShort == "say" && GreetShortMessage == "")) {
            setIvrGreetShortMessageError("Please enter greet short message")
        } else {
            setIvrGreetShortMessageError("")
        }

        if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptName == "") {
            setIvrShortNameError("Please enter a name for TTS prompt ")
        } else {
            setIvrShortNameError("")
        }

        if (SelectIvrGreetShort == "tts_prompt" && TtsShortPromptText == "") {
            setIvrShortTextError("Please enter a text for TTS prompt ")
        } else {
            setIvrShortTextError("")
        }

        if (SelectIvrGreetShort == "tts_prompt" && (TtsShortPromptLanguage == "1")) {
            setIvrShortLanguageError("Please select a language ")
        } else {
            setIvrShortLanguageError("")
        }

        if (SelectIvrGreetShort == "tts_prompt" && (TtsShortPromptVoiceType == "1")) {
            setIvrShortVoiceTypeError("Please select a voice type")
        } else {
            setIvrShortVoiceTypeError("")
        }

        if (IvrMaxFailures == "" || IvrMaxFailures < 0 || IvrMaxFailures > 20) {
            setIvrMaxFailuresError("Please enter max failures count ( in digits between 0 to 20 )")
        } else {
            setIvrMaxFailuresError("")
        }

        if (IvrMaxTimeout == "" || IvrMaxTimeout < 0 || IvrMaxTimeout > 20) {
            setIvrMaxTimeoutError("Please enter max timeout ( in digits between 0 to 20 )")
        } else {
            setIvrMaxTimeoutError("")
        }

        if (IvrTimeout == "" || IvrTimeout < 1000 || IvrTimeout > 9999) {
            setIvrTimeoutError("Please enter timeout in milli-seconds ( in digits between 1000 to 9999 )")
        } else {
            setIvrTimeoutError("")
        }

        if (IvrSelectIvrExitAction != "1" && IvrSelectIvrExitName == "0") {
            setIvrExitNameError("Please select an exit name")
        } else {
            setIvrExitNameError("")
        }

        if (IvrInvalidSound == "0") {
            setIvrInvalidSoundError("Please select an invalid sound")
        } else {
            setIvrInvalidSoundError("")
        }

        if (IvrExitSound == "0") {
            setIvrExitSoundError("Please select an exit sound")
        } else {
            setIvrExitSoundError("")
        }

        if (
            IvrName == "" ||
            (IvrExtension == "" || IvrExtension <= 0) ||
            SelectIvrGreetLong == "1" ||
            SelectIvrGreetShort == "1" ||
            (IvrMaxFailures == "" || IvrMaxFailures < 0 || IvrMaxFailures > 20) ||
            (IvrMaxTimeout == "" || IvrMaxTimeout < 0 || IvrMaxTimeout > 20) ||
            (IvrTimeout == "" || IvrTimeout < 1000 || IvrTimeout > 9999) ||
            IvrSelectRingBack == "1" ||
            IvrInvalidSound == "0" ||
            IvrExitSound == "0"
        ) {
            toast.warn("Please Enter all the Required Fields", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        if (
            (SelectIvrGreetLong == "say" && GreetLongMessage == "") ||
            (SelectIvrGreetShort == "say" && GreetShortMessage == "")
        ) {
            toast.warn("Please Enter all the Required Fields", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }
        if (
            (SelectIvrGreetLong == "tts_prompt" &&
                (TtsLongPromptName == "" ||
                    TtsLongPromptText == "" ||
                    TtsLongPromptLanguage == "1" ||
                    TtsLongPromptVoiceType == "1")) ||
            (SelectIvrGreetShort == "tts_prompt" &&
                (TtsShortPromptName == "" ||
                    TtsShortPromptText == "" ||
                    TtsShortPromptLanguage == "1" ||
                    TtsShortPromptVoiceType == "1"))
        ) {
            toast.warn("Please Enter all the Required Fields", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        if (IvrSelectIvrExitAction != "1" && IvrSelectIvrExitName == "0") {
            toast.warn("Please select an exit name", {
                position: "top-right",
                autoClose: 3000,
            });
            return;
        }

        var GreetShortValue = 0;
        if (SelectIvrGreetShort === "tts_prompt") {
            const result = await GetIdForTTSPrompt(
                accessToken,
                domainUUID,
                TtsShortPromptName,
                TtsShortPromptText,
                TtsShortPromptLanguage,
                TtsShortPromptVoiceType
            );

            GreetShortValue = result.UUID + ".mp3";
        } else {
            GreetShortValue = getFileLocationById(SelectIvrGreetShort).substring(
                getFileLocationById(SelectIvrGreetShort).lastIndexOf("/") + 1
            );
        }

        var GreetLongValue = 0;
        if (SelectIvrGreetLong === "tts_prompt") {
            const result = await GetIdForTTSPrompt(
                accessToken,
                domainUUID,
                TtsShortPromptName,
                TtsShortPromptText,
                TtsShortPromptLanguage,
                TtsShortPromptVoiceType
            );

            GreetLongValue = result.UUID + ".mp3";
        } else {
            GreetLongValue = getFileLocationById(SelectIvrGreetLong).substring(
                getFileLocationById(SelectIvrGreetLong).lastIndexOf("/") + 1
            );
        }

        if (
            GreetShortValue != 0 ||
            GreetLongValue != 0 ||
            SelectIvrGreetShort == "say" ||
            SelectIvrGreetLong == "say"
        ) {
            if (IsUpdateIvr) {
                EditIVRFunction(GreetShortValue, GreetLongValue);
            } else {
                AddIVRFunction(GreetShortValue, GreetLongValue);
            }
        }
    };

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const AddIVRFunction = async (GreetShortValue, GreetLongValue) => {
        const InvalidSoundValue = getFileLocationById(IvrInvalidSound).substring(
            getFileLocationById(IvrInvalidSound).lastIndexOf("/") + 1
        );

        const ExitSoundValue = getFileLocationById(IvrExitSound).substring(
            getFileLocationById(IvrExitSound).lastIndexOf("/") + 1
        );
        const result = await AddIVR(
            //Add ivr api
            accessToken,
            domainUUID,
            IvrName,
            IvrExtension,
            SelectIvrGreetLong,
            GreetLongMessage,
            GreetLongValue,
            SelectIvrGreetShort,
            GreetShortMessage,
            GreetShortValue,
            IvrTimeout,
            IvrSelectIvrExitAction,
            IvrSelectIvrExitName,
            IvrSelectDirectDial,
            IvrSelectRingBack,
            IvrCallerIdPrefix,
            InvalidSoundValue,
            ExitSoundValue,
            IvrStatus,
            IvrDescription,
            IvrMaxFailures,
            IvrMaxTimeout
        );

        if (result.Status == "1") {
            const NodeId = result.NodeId;
            const nodes = [
                {
                    id: `${NodeId}`,
                    type: "CreatedNewIVR",
                    style: { width: "fit-content" },
                    position: { x: Math.random() * 200, y: Math.random() * 200 },
                    ivrDescription: IvrDescription,
                    name: IvrName,
                    data: {
                        label: (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "89% 10%",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                    gap: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "5px",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <h2
                                        style={{
                                            fontWeight: "400",
                                            fontSize: "16px",
                                            display: "flex",
                                            alignItems: "center",
                                            margin: "0px",
                                            textTransform: "capitalize",
                                            color: "#094e6c",
                                            borderBottom: "1px #006d77 solid ",
                                            paddingBottom: "3px",
                                        }}
                                    >
                                        <MessageOutlined
                                            style={{
                                                fontSize: "18px",
                                                color: "#006d77",
                                                marginRight: "8px",
                                            }}
                                        />
                                        IVR
                                    </h2>

                                    <h3
                                        style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            margin: "0px",
                                            color: "#161a1d",
                                        }}
                                    >
                                        Name : <span style={{ fontWeight: "400" }}> {IvrName}</span>
                                    </h3>

                                    <p
                                        style={{
                                            border: "1px lightgrey solid",
                                            borderRadius: "4px",
                                            padding: "6px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            fontSize: "16px",
                                            fontStyle: "italic",
                                            backgroundColor: "#E9F6E6",
                                            margin: "0px 5px 0px 0px",
                                            textAlign: "justify",
                                            width: "95%",
                                        }}
                                    >
                                        IVR Description :<br /> {IvrDescription}
                                    </p>
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: "3px",
                                    }}
                                >
                                    {[
                                        "0",
                                        "1",
                                        "2",
                                        "3",
                                        "4",
                                        "5",
                                        "6",
                                        "7",
                                        "8",
                                        "9",
                                        "*",
                                        "#",
                                    ].map((value, index) => (
                                        <p style={{ margin: "0px" }}>{value}</p>
                                    ))}
                                </div>
                            </div>
                        ),
                    },

                    sourcePosition: "right",
                    targetPosition: "left",
                },
            ];

            const ivrDetailResult = await GetIvrListUuid(
                //api to get coonfiguration id newly created node
                accessToken,
                domainUUID,
                NodeId
            );
            if (ivrDetailResult.Status == "1") {
                const ivr_menu_uuid = ivrDetailResult.IvrList[0].ivr_menu_uuid;
                const channel = "IVR";

                const LocalFlow = { nodes, edges };
                localStorage.setItem("flow", JSON.stringify(LocalFlow));
                const flowvalue = localStorage.getItem("flow");

                const PublishChangesResult = await PublishChanges(
                    //publish changes api
                    accessToken,
                    domainUUID,
                    ivr_menu_uuid,
                    flowvalue,
                    channel
                );

                if (PublishChangesResult.status == false) {
                    toast.info("Error, Please try again", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                } else {
                    navigate("/flow_builder", {
                        state: {
                            configurationName: IvrName,
                            configurationId: ivr_menu_uuid,
                            channel: "IVR",
                            condition: "CreatedIVR",
                        },
                    });

                    HandleCancelButtonIvrModal()
                }
            }
        } else {
            toast.info(result.Msg, {
                position: "top-right",
                autoClose: 5000,
            });
        }
    };

    const FetchPromptList = async () => {
        const result = await ShowPromptList(accessToken, domainUUID);
        if (result.Status == "1") {
            setPromptList(result.prompt_list);
            setExitNameList(result.prompt_list);
        }
    };

    const FetchQueueListForIvr = async () => {
        const result = await ShowQueueListForIvr(accessToken, domainUUID);
        setExitNameList(result);
    };
    const FetchExtensionList = async () => {
        const result = await ShowExtensionsList(accessToken, domainUUID);

        const filteredResult = result.filter(
            (item) => item.voicemail_enable === "true"
        );
        setExitNameList(filteredResult);
    };

    // states for editing the IVR.
    const [EditIvrFlowUuid, setEditIvrFlowUuid] = useState("")
    const [IsUpdateIvr, setIsUpdateIvr] = useState(false)

    const HandleEditIvrFlow = async (editIvrId) => {
        setIsUpdateIvr(true)
        setCreateIvrConfigurationModal(true);
        setEditIvrFlowUuid(editIvrId);

        const result = await showIvrConfigurationList(accessToken, domainUUID);

        if (result.Status === "1") {
            const selectedIvr = result.IvrList.find((ivr) => ivr.ivr_menu_uuid === editIvrId);

            if (selectedIvr) {
                // Populate the modal form fields with the selected IVR details
                setIvrName(selectedIvr.ivr_menu_name || "");
                setIvrExtension(selectedIvr.ivr_menu_extension || "");

                setSelectIvrGreetLong(getLocationUuid(selectedIvr.ivr_menu_greet_long, setGreetLongMessage))

                setSelectIvrGreetShort(getLocationUuid(selectedIvr.ivr_menu_greet_short, setGreetShortMessage))

                setIvrMaxFailures(selectedIvr.ivr_menu_max_failures || "2")
                setIvrMaxTimeout(selectedIvr.ivr_menu_max_failures || "2")
                setIvrTimeout(selectedIvr.ivr_menu_timeout || "3000")
                setIvrStatus(selectedIvr.ivr_menu_enabled)

                setIvrSelectIvrExitAction(selectedIvr.ivr_menu_exit_app || "1")
                setIvrSelectIvrExitName(selectedIvr.ivr_menu_exit_data || "")

                setIvrDescription(selectedIvr.ivr_menu_description || "")
                setIvrSelectDirectDial(selectedIvr.ivr_menu_direct_dial)
                setIvrSelectRingBack(selectedIvr.ivr_menu_ringback || "${in-ring}")
                setIvrCallerIdPrefix(selectedIvr.ivr_menu_cid_prefix || "")

                setIvrInvalidSound(getSoundUuid(selectedIvr.ivr_menu_invalid_sound) || "0")
                setIvrExitSound(getSoundUuid(selectedIvr.ivr_menu_exit_sound) || "0")
            }
        }
    };

    const EditIVRFunction = async (GreetShortValue, GreetLongValue) => {
        const InvalidSoundValue = getFileLocationById(IvrInvalidSound).substring(
            getFileLocationById(IvrInvalidSound).lastIndexOf("/") + 1
        );

        const ExitSoundValue = getFileLocationById(IvrExitSound).substring(
            getFileLocationById(IvrExitSound).lastIndexOf("/") + 1
        );
        const result = await EditIvr(
            accessToken,
            domainUUID,
            EditIvrFlowUuid,
            IvrName,
            IvrExtension,
            SelectIvrGreetLong,
            GreetLongMessage,
            GreetLongValue,
            SelectIvrGreetShort,
            GreetShortMessage,
            GreetShortValue,
            IvrTimeout,
            IvrSelectIvrExitAction,
            IvrSelectIvrExitName,
            IvrSelectDirectDial,
            IvrSelectRingBack,
            IvrCallerIdPrefix,
            InvalidSoundValue,
            ExitSoundValue,
            IvrStatus,
            IvrDescription,
            IvrMaxFailures,
            IvrMaxTimeout
        );

        if (result.Status == "1") {
            toast.success(result.Msg, {
                position: "top-right",
                autoClose: 5000,
            });
            HandleCancelButtonIvrModal()
        } else {
            toast.error(result.Msg, {
                position: "top-right",
                autoClose: 5000,
            });
        }
    };


    useEffect(() => {
        FetchPromptList();
    }, []);

    useEffect(() => {

        if (IvrSelectIvrExitAction == "IVR") {
            fetchIvrFlowsList();
        }
        if (IvrSelectIvrExitAction == "QUEUE") {
            FetchQueueListForIvr();
        }
        if (IvrSelectIvrExitAction == "PLAYBACK") {
            FetchPromptList();
        }
        if (IvrSelectIvrExitAction == "VOICEMAIL") {
            FetchExtensionList();
        }
    }, [IvrSelectIvrExitAction]);


    useEffect(() => {
        FetchAllFlowList()
    }, [DeleteConfigurationModal, CreateVoiceBotConfigurationModal, CreateIvrConfigurationModal, CreateOmniChannelConfigurationModal, CreateSmsConfigurationModal]);


    return (
        <>
            <div className="FrontPage">
                <div className="Section_1">
                    <div>
                        <h2>Create a new flow</h2>
                        <p>
                            Start building your custom flows or use the pre-defined
                            templates
                        </p>
                    </div>

                    <div id="SearchBox">
                        <input
                            type="text"
                            placeholder="Search flow"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                        />
                        <Search id="SearchIcon" />
                    </div>
                </div>

                <div className="TabMenus">
                    <h3
                        className={TabMenu === "Voice Bot" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("Voice Bot");
                            setSearchQuery("");
                        }}
                    >
                        Voice Bot
                    </h3>
                    <h3
                        className={TabMenu === "WhatsApp Bot" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("WhatsApp Bot");
                            setSearchQuery("");
                        }}
                    >
                        WhatsApp
                    </h3>
                    <h3
                        className={TabMenu === "Telegram Bot" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("Telegram Bot");
                            setSearchQuery("");
                        }}
                    >
                        Telegram
                    </h3>
                    <h3
                        className={TabMenu === "Viber Bot" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("Viber Bot");
                            setSearchQuery("");
                        }}
                    >
                        Viber
                    </h3>
                    <h3
                        className={TabMenu === "IVR" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("IVR");
                            setSearchQuery("");
                        }}
                    >
                        IVR
                    </h3>

                    {/* <h3
                        className={TabMenu === "SMS" ? "active" : ""}
                        onClick={() => {
                            setTabMenu("SMS");
                            setSearchQuery("");
                        }}
                    >
                        SMS
                    </h3> */}
                </div>

                <div className="Section_2">
                    {TabMenu == "Voice Bot" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateVoiceBotConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create Voice Bot Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new Voice bot flow for your channels. </p>
                        </div>
                    )}
                    {TabMenu == "IVR" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateIvrConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create IVR Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new IVR flow for your channels. </p>
                        </div>
                    )}

                    {TabMenu == "SMS" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateSmsConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create SMS Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new SMS flow for your channels. </p>
                        </div>
                    )}
                    {TabMenu == "WhatsApp Bot" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateOmniChannelConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create WhatsApp Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new WhatsApp flow based on your conditions. </p>
                        </div>
                    )}

                    {TabMenu == "Telegram Bot" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateOmniChannelConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create Telegram Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new Telegram flow for your channels. </p>
                        </div>
                    )}

                    {TabMenu == "Viber Bot" && (
                        <div
                            className="Card"
                            id="StaticCard"
                            style={{ backgroundColor: "#f5f5f5" }}
                            onClick={() => setCreateOmniChannelConfigurationModal(true)}
                        >
                            <div>
                                <h3>Create Viber Flow</h3>
                                <Add id="AddIcon" />
                            </div>
                            <p> Start creating a new Viber flow for your channels. </p>
                        </div>
                    )}

                    {/* Showing various flows data */}
                    {TabMenu === "Voice Bot" &&
                        (VoiceBotList.length > 0 ? (
                            VoiceBotList.filter((bot) =>
                                bot.configuration_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                            ).map((data, index) => {
                                return (
                                    <div
                                        className="Card"
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(
                                                data.configuration_uuid,
                                                data.configuration_name,
                                                "voiceBot"
                                            )
                                        }
                                    >
                                        <div>
                                            <Timeline id="AddIcon" />
                                            <h3>{data.configuration_name}</h3>

                                            <Delete
                                                id="DeleteIcon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    HandleDeleteButton(
                                                        data.configuration_uuid,
                                                        data.configuration_name
                                                    );
                                                }}
                                            />
                                        </div>
                                        <p>
                                            {data.description
                                                ? data.description
                                                : "No preview available"}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>
                                    There are no Voice Bot configurations available at present.
                                </p>
                            </div>
                        ))}

                    {TabMenu === "WhatsApp Bot" &&
                        (WhatsappBotList.length > 0 ? (
                            WhatsappBotList.filter((bot) =>
                                bot.configuration_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                            ).map((data, index) => {
                                return (
                                    <div
                                        className="Card"
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(
                                                data.appId,
                                                data.configuration_name,
                                                "whatsapp"
                                            )
                                        }
                                    >
                                        <div>
                                            <Timeline id="AddIcon" />
                                            <h3>{data.configuration_name}</h3>
                                            <Delete
                                                id="DeleteIcon"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the event from bubbling up to the Card's onClick
                                                    HandleDeleteButton(data.id, data.configuration_name);
                                                }}
                                            />
                                        </div>
                                        <p>
                                            <span>Trigger Name: </span>
                                            {data.trigger_name
                                                ? data.trigger_name
                                                : "No preview available"}
                                        </p>
                                        <p>
                                            <span>Trigger Message: </span>
                                            {data.trigger_message ? data.trigger_message : " -- "}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>
                                    There are no WhatsApp configurations available at present.
                                </p>
                            </div>
                        ))}

                    {TabMenu == "Telegram Bot" &&
                        (TelegramBotList.length > 0 ? (
                            TelegramBotList.filter((bot) =>
                                bot.configuration_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                            ).map((data, index) => {
                                return (
                                    <div
                                        className="Card"
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(
                                                data.appId,
                                                data.configuration_name,
                                                "telegram"
                                            )
                                        }
                                    >
                                        <div>
                                            <Timeline id="AddIcon" />
                                            <h3>{data.configuration_name}</h3>
                                            <Delete
                                                id="DeleteIcon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    HandleDeleteButton(data.id, data.configuration_name);
                                                }}
                                            />
                                        </div>
                                        <p>
                                            <span>Trigger Name : </span>{" "}
                                            {data.trigger_name
                                                ? data.trigger_name
                                                : "No preview available"}{" "}
                                        </p>
                                        <p>
                                            <span>Trigger Message : </span>{" "}
                                            {data.trigger_message ? data.trigger_message : " -- "}{" "}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>
                                    There are no Telegram configurations available at present.
                                </p>
                            </div>
                        ))}

                    {TabMenu == "Viber Bot" &&
                        (ViberBotList.length > 0 ? (
                            ViberBotList.filter((bot) =>
                                bot.configuration_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                            ).map((data, index) => {
                                return (
                                    <div
                                        className="Card"
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(
                                                data.appId,
                                                data.configuration_name,
                                                "viber"
                                            )
                                        }
                                    >
                                        <div>
                                            <Timeline id="AddIcon" />
                                            <h3>{data.configuration_name}</h3>
                                            <Delete
                                                id="DeleteIcon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    HandleDeleteButton(data.id, data.configuration_name);
                                                }}
                                            />
                                        </div>
                                        <p>
                                            <span>Trigger Name : </span>{" "}
                                            {data.trigger_name
                                                ? data.trigger_name
                                                : "No preview available"}{" "}
                                        </p>
                                        <p>
                                            <span>Trigger Message : </span>{" "}
                                            {data.trigger_message ? data.trigger_message : " -- "}{" "}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>
                                    There are no Viber configurations available at present.
                                </p>
                            </div>
                        ))}

                    {TabMenu == "IVR" &&
                        (IvrBotList.length > 0 ? (
                            IvrBotList.filter((bot) =>
                                bot.ivr_menu_name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                            ).map((data, index) => {
                                return (
                                    <div
                                        className="Card"
                                        key={index}
                                        onClick={() =>
                                            handleNavigate(
                                                data.ivr_menu_uuid,
                                                data.ivr_menu_name,
                                                "IVR"
                                            )
                                        }
                                    >
                                        <div>
                                            <Timeline id="AddIcon" />
                                            <h3>{data.ivr_menu_name}</h3>

                                            <div>

                                                <Edit
                                                    id="EditIcon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        HandleEditIvrFlow(
                                                            data.ivr_menu_uuid,
                                                        );
                                                    }}
                                                />

                                                <Delete
                                                    id="DeleteIcon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        HandleDeleteButton(
                                                            data.ivr_menu_uuid,
                                                            data.ivr_menu_name
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <p>
                                            {" "}
                                            {data.ivr_menu_description
                                                ? data.ivr_menu_description
                                                : "No preview available"}{" "}
                                        </p>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>There are no IVR configurations available at present.</p>
                            </div>
                        ))}

                    {TabMenu == "SMS" &&
                        (smsBroadcastList.length > 0 ? (
                            smsBroadcastList
                                .filter((bot) =>
                                    bot.broadcast_name
                                        .toLowerCase()
                                        .includes(searchQuery.toLowerCase())
                                )
                                .map((data, index) => {
                                    return (
                                        <div
                                            className="Card"
                                            key={index}
                                            onClick={() =>
                                                handleNavigate(
                                                    data.sms_broadcast_uuid,
                                                    data.broadcast_name,
                                                    "smsBroadcast"
                                                )
                                            }
                                        >
                                            <div>
                                                <Timeline id="AddIcon" />
                                                <h3>{data.broadcast_name}</h3>
                                                <Delete
                                                    id="DeleteIcon"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        HandleDeleteButton(
                                                            data.sms_broadcast_uuid,
                                                            data.broadcast_name
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <p>
                                                {" "}
                                                {data.description
                                                    ? data.description
                                                    : "No preview available"}{" "}
                                            </p>
                                        </div>
                                    );
                                })
                        ) : (
                            <div className="Card">
                                <div>
                                    <Timeline id="AddIcon" />
                                    <h3>No Data Available</h3>
                                </div>
                                <p>There are no SMS broadcast available at present.</p>
                            </div>
                        ))}
                </div>
            </div>

            {/* This modal is for deleting the configuration */}
            <Modal
                open={DeleteConfigurationModal}
                onClose={() => { setDeleteConfigurationModal(false); }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault(); // Prevent form submission reload
                        DeleteConfiguration(); // Call the deletion function on submit
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault(); // Prevent form default behavior on "Enter" key
                            DeleteConfiguration(); // Call the deletion function when "Enter" is pressed
                        }
                    }}
                >
                    <Box sx={DeleteVoiceBotStyle} style={{ width: "40%" }}>
                        <h3
                            style={{
                                fontSize: "20px",
                                width: "100%",
                                fontWeight: "300",
                                marginBottom: "10px",
                                textAlign: "center",
                                lineHeight: "30px",
                            }}
                        >
                            Are you sure you want to delete this{" "}
                            <span
                                style={{
                                    fontWeight: "500",
                                    color: "#4B64FF",
                                    textTransform: "capitalize",
                                }}
                            >
                                {DeleteConfigurationName}
                            </span>{" "}
                            configuration?
                        </h3>

                        <div style={{ width: "fit-content", margin: "25px auto 10px auto " }}>
                            <Button
                                type="submit"
                                id="DeleteButtonFrontPage"
                            >
                                Delete
                            </Button>

                            <Button
                                onClick={() => { setDeleteConfigurationModal(false); }}
                                id="CancelButtonFrontPage"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Box>
                </form>
            </Modal>

            {/* Modal for creating the Voice Bot configuration */}
            <Modal
                open={CreateVoiceBotConfigurationModal}
                onClose={() => HandleCancelButtonVoiceBotModal()}
            >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleAddVoiceBotConfiguration();
                }}>
                    <Box
                        sx={{ ...voiceBotStyle }}
                        id="IvrConfigurationModal"
                        style={{ height: "fit-content", rowGap: '30px' }}
                    >
                        <h2>Add Voice Bot Configuration</h2>

                        <div id="AllInputBoxes">
                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter configuration name <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter name here"
                                        variant="outlined"
                                        value={voiceBotConfigurationName}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setVoiceBotConfigurationName,
                                            setVoiceBotNameError,
                                            "Please enter configuration name."
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    {
                                        VoiceBotNameError &&
                                        <p id="ErrorMessage" > {VoiceBotNameError} </p>
                                    }
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter Description
                                        <span style={{ opacity: '0' }} >*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter extension here"
                                        variant="outlined"
                                        value={voiceBotDescription}
                                        onChange={(e) => setVoiceBotDescription(e.target.value)}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div
                                style={{
                                    width: "100%",
                                    display: "grid",
                                    alignItems: "center",
                                    gridTemplateColumns: "20% 80%",
                                    justifyContent: "space-between",
                                    padding: "0px 5px",
                                }}
                            >
                                <p
                                    style={{ color: "#746df7", fontSize: "18px", fontWeight: "500" }}
                                >
                                    Max Retry :{" "}
                                </p>
                                <Slider
                                    value={VoiceBotRetryValue}
                                    onChange={(e) => setVoiceBotRetryValue(e.target.value)}
                                    valueLabelDisplay="auto"
                                    step={1}
                                    marks={VoiceBotRetryValueArray}
                                    min={1}
                                    max={5}
                                    style={{ width: "100%", marginTop: "20px " }}
                                />
                            </div>

                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: 'center',
                                columnGap: "15px",
                                paddingTop: '10px'
                            }}
                        >
                            <Button
                                variant="outlined"
                                id="CancelButtonFrontPage"
                                onClick={() => HandleCancelButtonVoiceBotModal()}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                id="AddFlowButton"
                                variant="contained"
                            // onClick={handleAddVoiceBotConfiguration}
                            >
                                Add
                            </Button>
                        </div>
                    </Box>
                </form>
            </Modal>

            {/* Modal for creating the IVR configuration */}
            <Modal open={CreateIvrConfigurationModal}
                onClose={() => HandleCancelButtonIvrModal()}
            >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleIvrAddEdit();
                }}>
                    <Box sx={{ ...ivrModalStyle }} id="IvrConfigurationModal">

                        <h2>
                            {IsUpdateIvr ? "Edit IVR Configuration" : "Add New IVR Configuration"}
                        </h2>

                        <div id="AllInputBoxes">
                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter IVR Name <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter name here"
                                        variant="outlined"
                                        value={IvrName}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setIvrName,
                                            setIvrNameError,
                                            "Please enter the IVR name"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            IvrNameError && IvrNameError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter IVR Extension <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter extension here in digits"
                                        type="number"
                                        variant="outlined"
                                        value={IvrExtension}
                                        onChange={(e) => ErrorHandlingExtension(
                                            e,
                                            setIvrExtension,
                                            setIvrExtensionError,
                                            "Please enter a valid IVR extension using digits greater than zero"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                        inputProps={{ min: 1 }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            IvrExtensionError && IvrExtensionError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select Greet Long<span>*</span>
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SelectIvrGreetLong}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSelectIvrGreetLong,
                                            setIvrGreetLongError,
                                            "Please select a greet long"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="1" disabled>
                                            -- Select Greet Long--
                                        </MenuItem>
                                        <MenuItem value="say">Say</MenuItem>
                                        <MenuItem value="tts_prompt">TTS Prompt</MenuItem>

                                        {PromptList ? (
                                            PromptList.map((option) => (
                                                <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                                                    {option.prompt_name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No data</MenuItem>
                                        )}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {
                                            IvrGreetLongError && IvrGreetLongError
                                        }
                                    </p>
                                </div>

                                {SelectIvrGreetLong == "say" && (
                                    <div id="InputBox">
                                        <p>
                                            {" "}
                                            Enter Greet Long Message <span>*</span>
                                        </p>
                                        <TextField
                                            placeholder="Enter message here"
                                            variant="outlined"
                                            value={GreetLongMessage}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setGreetLongMessage,
                                                setIvrGreetLongMessageError,
                                                "Please enter greet long message"
                                            )}
                                            fullWidth
                                        />
                                        <p id="ErrorMessage" >
                                            {
                                                IvrGreetLongMessageError && IvrGreetLongMessageError
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>

                            {SelectIvrGreetLong == "tts_prompt" && (
                                <>
                                    <div id="RowBox">
                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Enter Name For TTS Prompt <span>*</span>{" "}
                                            </p>
                                            <TextField
                                                placeholder="Enter name here"
                                                variant="outlined"
                                                value={TtsLongPromptName}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsLongPromptName,
                                                    setIvrLongNameError,
                                                    "Please enter a name for TTS prompt"
                                                )}
                                                fullWidth
                                                style={{ width: "100%" }}
                                            />
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrLongNameError && IvrLongNameError
                                                }
                                            </p>
                                        </div>

                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Enter Text For TTS Prompt <span>*</span>{" "}
                                            </p>
                                            <TextField
                                                placeholder="Enter text here"
                                                variant="outlined"
                                                value={TtsLongPromptText}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsLongPromptText,
                                                    setIvrLongTextError,
                                                    "Please enter a text for TTS prompt "
                                                )}
                                                fullWidth
                                                style={{ width: "100%" }}
                                            />
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrLongTextError && IvrLongTextError
                                                }
                                            </p>
                                        </div>
                                    </div>

                                    <div id="RowBox">
                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Select Language For Greet Long <span>*</span>{" "}
                                            </p>

                                            <Select
                                                id="node-type"
                                                value={TtsLongPromptLanguage}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsLongPromptLanguage,
                                                    setIvrLongLanguageError,
                                                    "Please select a language"
                                                )}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value="1" disabled>
                                                    Select Language For Greet Long
                                                </MenuItem>
                                                <MenuItem value="en-US">English</MenuItem>
                                                <MenuItem value="hi-IN">Hindi</MenuItem>
                                            </Select>
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrLongLanguageError && IvrLongLanguageError
                                                }
                                            </p>
                                        </div>

                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Select Voice Type For Greet Long <span>*</span>{" "}
                                            </p>
                                            <Select
                                                id="node-type"
                                                value={TtsLongPromptVoiceType}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsLongPromptVoiceType,
                                                    setIvrLongVoiceTypeError,
                                                    "Please select a voice type"
                                                )}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value="1" disabled>
                                                    Select Voice Type for Greet Long
                                                </MenuItem>
                                                <MenuItem value="male">Male</MenuItem>
                                                <MenuItem value="female">Female</MenuItem>
                                            </Select>
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrLongVoiceTypeError && IvrLongVoiceTypeError
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select Greet Short <span>*</span>{" "}
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SelectIvrGreetShort}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSelectIvrGreetShort,
                                            setIvrGreetShortError,
                                            "Please select a greet short"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="1" disabled>
                                            Select Greet Short
                                        </MenuItem>
                                        <MenuItem value="say">Say</MenuItem>
                                        <MenuItem value="tts_prompt">TTS Prompt</MenuItem>
                                        {PromptList ? (
                                            PromptList.map((option) => (
                                                <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                                                    {option.prompt_name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No data</MenuItem>
                                        )}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {
                                            IvrGreetShortError && IvrGreetShortError
                                        }
                                    </p>
                                </div>

                                {SelectIvrGreetShort == "say" && (
                                    <div id="InputBox">
                                        <p>
                                            {" "}
                                            Enter Greet Short Message <span>*</span>{" "}
                                        </p>
                                        <TextField
                                            placeholder="Enter message here"
                                            variant="outlined"
                                            value={GreetShortMessage}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setGreetShortMessage,
                                                setIvrGreetShortMessageError,
                                                "Please enter greet short message"
                                            )}
                                            fullWidth
                                        />
                                        <p id="ErrorMessage" >
                                            {
                                                IvrGreetShortMessageError && IvrGreetShortMessageError
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>

                            {SelectIvrGreetShort == "tts_prompt" && (
                                <>
                                    <div id="RowBox">
                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Enter Name For TTS Prompt <span>*</span>{" "}
                                            </p>
                                            <TextField
                                                placeholder="Enter name here"
                                                variant="outlined"
                                                value={TtsShortPromptName}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTTSShortPromptName,
                                                    setIvrShortNameError,
                                                    "Please enter a name for TTS prompt"
                                                )}
                                                fullWidth
                                            />
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrShortNameError && IvrShortNameError
                                                }
                                            </p>
                                        </div>

                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Enter Text For TTS Prompt <span>*</span>{" "}
                                            </p>
                                            <TextField
                                                placeholder="Enter text here"
                                                variant="outlined"
                                                value={TtsShortPromptText}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTTSShortPromptText,
                                                    setIvrShortTextError,
                                                    "Please enter a text for TTS prompt"
                                                )}
                                                fullWidth
                                            />
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrShortTextError && IvrShortTextError
                                                }
                                            </p>
                                        </div>
                                    </div>

                                    <div id="RowBox">
                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Select Language For Greet Short <span>*</span>{" "}
                                            </p>
                                            <Select
                                                id="node-type"
                                                value={TtsShortPromptLanguage}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsShortPromptLanguage,
                                                    setIvrShortLanguageError,
                                                    "Please select a language"
                                                )}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value="1" disabled>
                                                    Select Language For Greet Short
                                                </MenuItem>
                                                <MenuItem value="en-US">English</MenuItem>
                                                <MenuItem value="hi-IN">Hindi</MenuItem>
                                            </Select>
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrShortLanguageError && IvrShortLanguageError
                                                }
                                            </p>
                                        </div>

                                        <div id="InputBox">
                                            <p>
                                                {" "}
                                                Select Voice Type For Greet Short <span>*</span>{" "}
                                            </p>
                                            <Select
                                                id="node-type"
                                                value={TtsShortPromptVoiceType}
                                                onChange={(e) => ErrorHandlingFunction(
                                                    e,
                                                    setTtsShortPromptVoiceType,
                                                    setIvrShortVoiceTypeError,
                                                    "Please select a voice type"
                                                )}
                                                style={{ width: "100%" }}
                                            >
                                                <MenuItem value="1" disabled>
                                                    Select Voice Type For Greet Short
                                                </MenuItem>
                                                <MenuItem value="male">Male</MenuItem>
                                                <MenuItem value="female">Female</MenuItem>
                                            </Select>
                                            <p id="ErrorMessage" >
                                                {
                                                    IvrShortVoiceTypeError && IvrShortVoiceTypeError
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter Max Failures <span>*</span>{" "}
                                    </p>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter failures here"
                                        type="number"
                                        value={IvrMaxFailures}
                                        onChange={(e) => ErrorHandlingMaxFailures(
                                            e,
                                            setIvrMaxFailures,
                                            setIvrMaxFailuresError,
                                            "Please enter max failures count ( in digts between 0 to 20 )"
                                        )}
                                        fullWidth
                                        inputProps={{ min: 0 }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            IvrMaxFailuresError && IvrMaxFailuresError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter Max Timeout <span>*</span>{" "}
                                    </p>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Enter timeout here"
                                        type="number"
                                        value={IvrMaxTimeout}
                                        onChange={(e) => ErrorHandlingMaxTimeout(
                                            e,
                                            setIvrMaxTimeout,
                                            setIvrMaxTimeoutError,
                                            "Please enter max timeout ( in digits between 0 to 20 )"
                                        )}
                                        fullWidth
                                        inputProps={{ min: 0 }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            IvrMaxTimeoutError && IvrMaxTimeoutError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        Enter Timeout (ms) <span>*</span>{" "}
                                    </p>
                                    <TextField
                                        placeholder="Timeout (ms)"
                                        variant="outlined"
                                        type="number"
                                        value={IvrTimeout}
                                        onChange={(e) => ErrorHandlingTimeout(
                                            e,
                                            setIvrTimeout,
                                            setIvrTimeoutError,
                                            "Please enter timeout in milli-seconds ( in digits between 1000 to 9999 )"
                                        )}
                                        fullWidth
                                        inputProps={{ min: 1000 }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            IvrTimeoutError && IvrTimeoutError
                                        }
                                    </p>
                                </div>


                            </div>

                            <div id="RowBox">
                                {/* <div
                                    style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridTemplateColumns: '45% 45%',
                                        width: "100%",
                                        columnGap: "20px",
                                        padding: "20px 5px",
                                    }}
                                >
                                    <p style={{ margin: '0px' }}>Status</p>

                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={IvrStatus}
                                                onChange={(e) => setIvrStatus(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </div> */}
                                <div id="InputBox">
                                    <p>Select Exit Action <span style={{ opacity: '0' }} >*</span> </p>
                                    <Select
                                        id="node-type"
                                        value={IvrSelectIvrExitAction}
                                        onChange={(e) => {
                                            setIvrSelectIvrExitAction(e.target.value);
                                            setIvrSelectIvrExitName("0")
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="1">
                                            Select Exit Action
                                        </MenuItem>
                                        <MenuItem value="IVR">IVR</MenuItem>
                                        <MenuItem value="QUEUE">QUEUE</MenuItem>
                                        <MenuItem value="PLAYBACK">PLAYBACK</MenuItem>
                                        <MenuItem value="VOICEMAIL">VOICEMAIL</MenuItem>
                                    </Select>
                                </div>

                                {IvrSelectIvrExitAction !== "1" && (
                                    <div id="InputBox">
                                        <p>
                                            Select Exit Name <span>*</span>{" "}
                                        </p>
                                        <Select
                                            id="node-type"
                                            value={IvrSelectIvrExitName}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setIvrSelectIvrExitName,
                                                setIvrExitNameError,
                                                "Please select an exit name"
                                            )}
                                            style={{ width: "100%" }}
                                        >
                                            <MenuItem value="0" disabled>
                                                Select Exit Name
                                            </MenuItem>
                                            {ExitNameList && IvrSelectIvrExitAction != "" ? (
                                                ExitNameList.map((option) => (
                                                    <MenuItem
                                                        key={
                                                            option.ivr_menu_uuid
                                                                ? option.ivr_menu_uuid
                                                                : option.call_center_queue_uuid
                                                                    ? option.call_center_queue_uuid
                                                                    : option.pmt_uuid
                                                                        ? option.pmt_uuid
                                                                        : option.extension_uuid
                                                        }
                                                        value={
                                                            option.ivr_menu_uuid
                                                                ? option.ivr_menu_uuid
                                                                : option.call_center_queue_uuid
                                                                    ? option.call_center_queue_uuid
                                                                    : option.pmt_uuid
                                                                        ? option.pmt_uuid
                                                                        : option.extension_uuid
                                                        }
                                                    >
                                                        {option.ivr_menu_name
                                                            ? option.ivr_menu_name
                                                            : option.queue_name
                                                                ? option.queue_name
                                                                : option.prompt_name
                                                                    ? option.prompt_name
                                                                    : option.extension}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No data</MenuItem>
                                            )}
                                        </Select>
                                        <p id="ErrorMessage" >
                                            {
                                                IvrExitNameError && IvrExitNameError
                                            }
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>Enter Description  <span style={{ opacity: '0' }} >*</span> </p>
                                    <TextField
                                        placeholder="Enter description here"
                                        variant="outlined"
                                        value={IvrDescription}
                                        onChange={(e) => setIvrDescription(e.target.value)}
                                        fullWidth
                                    />
                                </div>

                                <div id="InputBox">
                                    <p>
                                        Select Direct Dial <span>*</span>{" "}
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={IvrSelectDirectDial}
                                        onChange={(e) => setIvrSelectDirectDial(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="true">True</MenuItem>
                                        <MenuItem value="false">False</MenuItem>
                                    </Select>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        Select Ring Back <span>*</span>{" "}
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={IvrSelectRingBack}
                                        onChange={(e) => setIvrSelectRingBack(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="1" disabled>
                                            Select Ring Back
                                        </MenuItem>
                                        <MenuItem value="${us-ring}">us-ring</MenuItem>
                                        <MenuItem value="${fi-ring}">fi-ring</MenuItem>
                                        <MenuItem value="${fr-ring}">fr-ring</MenuItem>
                                        <MenuItem value="${pt-ring}">pt-ring</MenuItem>
                                        <MenuItem value="${hk-ring}">hk-ring</MenuItem>
                                        <MenuItem value="${hu-ring}">hu-ring</MenuItem>
                                        <MenuItem value="${il-ring}">il-ring</MenuItem>
                                        <MenuItem value="${in-ring}">in-ring</MenuItem>
                                        <MenuItem value="${jp-ring}">jp-ring</MenuItem>
                                        <MenuItem value="${ko-ring}">ko-ring</MenuItem>
                                        <MenuItem value="${pk-ring}">pk-ring</MenuItem>
                                        <MenuItem value="${pl-ring}">pl-ring</MenuItem>
                                        <MenuItem value="${ro-ring}">ro-ring</MenuItem>
                                        <MenuItem value="${rs-ring}">rs-ring</MenuItem>
                                        <MenuItem value="${it-ring}">it-ring</MenuItem>
                                        <MenuItem value="${ru-ring}">ru-ring</MenuItem>
                                        <MenuItem value="${sa-ring}">sa-ring</MenuItem>
                                        <MenuItem value="${tr-ring}">tr-ring</MenuItem>
                                        <MenuItem value="${uk-ring}">uk-ring</MenuItem>
                                        <MenuItem value="${bong-ri}ng">bong-ring</MenuItem>
                                        <MenuItem value="${au-ring}">au-ring</MenuItem>
                                        <MenuItem value="${be-ring}">be-ring</MenuItem>
                                        <MenuItem value="${ca-ring}">ca-ring</MenuItem>
                                        <MenuItem value="${cn-ring}">cn-ring</MenuItem>
                                        <MenuItem value="${cy-ring}">cy-ring</MenuItem>
                                        <MenuItem value="${cz-ring}">cz-ring</MenuItem>
                                        <MenuItem value="${de-ring}">de-ring</MenuItem>
                                        <MenuItem value="${dk-ring}">dk-ring</MenuItem>
                                        <MenuItem value="${dz-ring}">dz-ring</MenuItem>
                                        <MenuItem value="${eg-ring}">eg-ring</MenuItem>
                                    </Select>
                                </div>

                                <div id="InputBox">
                                    <p>Enter Caller ID Name Prefix <span style={{ opacity: '0' }} >*</span> </p>
                                    <TextField
                                        placeholder="Enter prefix here"
                                        variant="outlined"
                                        value={IvrCallerIdPrefix}
                                        onChange={(e) => setIvrCallerIdPrefix(e.target.value)}
                                        fullWidth
                                    />
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        Select Invalid Sound <span>*</span>{" "}
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={IvrInvalidSound}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setIvrInvalidSound,
                                            setIvrInvalidSoundError,
                                            "Please select an invalid sound"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Invalid Sound
                                        </MenuItem>
                                        {PromptList ? (
                                            PromptList.map((option) => (
                                                <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                                                    {option.prompt_name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No data</MenuItem>
                                        )}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {
                                            IvrInvalidSoundError && IvrInvalidSoundError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        Select Exit Sound <span>*</span>{" "}
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={IvrExitSound}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setIvrExitSound,
                                            setIvrExitSoundError,
                                            "Please select an exit sound"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Exit Sound
                                        </MenuItem>
                                        {PromptList ? (
                                            PromptList.map((option) => (
                                                <MenuItem key={option.pmt_uuid} value={option.pmt_uuid}>
                                                    {option.prompt_name}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No data</MenuItem>
                                        )}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {
                                            IvrExitSoundError && IvrExitSoundError
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: 'center',
                                columnGap: "15px",
                                paddingTop: '10px'
                            }}
                        >
                            <Button
                                id="CancelButtonFrontPage"
                                onClick={() => HandleCancelButtonIvrModal()}
                            >
                                Cancel
                            </Button>

                            {
                                IsUpdateIvr ?
                                    <Button
                                        type="submit"
                                        id="AddFlowButton"
                                        variant="contained"
                                    >
                                        Edit
                                    </Button> :
                                    <Button
                                        type="submit"
                                        id="AddFlowButton"
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                            }
                        </div>
                    </Box>
                </form>
            </Modal>

            {/* Modal for creating the OmniChannel configuration */}
            <Modal open={CreateOmniChannelConfigurationModal}
                onClose={ClearOmniChannelModalStates} >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    HandleCreateOmniChannelConfiguration();
                }}>
                    <Box sx={{ ...ivrModalStyle }} id="IvrConfigurationModal">
                        <h2>Add New {" "}
                            {TabMenu == "WhatsApp Bot" && "WhatsApp"}
                            {TabMenu == "Telegram Bot" && "Telegram"}
                            {TabMenu == "Viber Bot" && "Viber"}
                            {" "} Configuration
                        </h2>

                        <div id="AllInputBoxes">
                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Configuration name <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter configuration name"
                                        variant="outlined"
                                        value={OmniChannelConfigurationName}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setOmniChannelConfigurationName,
                                            setOmniChannelNameError,
                                            "Please enter configuration name"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelNameError && OmniChannelNameError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox" >
                                    <p>
                                        {" "}
                                        App ID <span>*</span>
                                    </p>
                                    <div style={{ position: 'relative', width: '100%' }} >
                                        <TextField
                                            placeholder="Enter App ID"
                                            variant="outlined"
                                            value={OmniChannelConfigurationAppId}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setOmniChannelConfigurationAppId,
                                                setOmniChannelAppIdError,
                                                "Please enter configuration app ID"
                                            )}
                                            fullWidth
                                            type={ShowOmniChannelConfigurationAppId ? "text" : "password"}
                                            style={{ width: "100%" }}
                                        />

                                        <span
                                            onClick={() =>
                                                setShowOmniChannelConfigurationAppId(!ShowOmniChannelConfigurationAppId)
                                            }
                                        >
                                            {ShowOmniChannelConfigurationAppId ? (
                                                <Visibility id="EyeIcon" />
                                            ) : (
                                                <VisibilityOff id="EyeIcon" />
                                            )}
                                        </span>
                                    </div>

                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelAppIdError &&
                                            OmniChannelAppIdError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Chat limit <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter chat limit"
                                        variant="outlined"
                                        value={OmniChannelConfigurationChatLimit}
                                        onChange={(e) => ErrorHandlingChatLimit(
                                            e,
                                            setOmniChannelConfigurationChatLimit,
                                            setOmniChannelChatLimitError,
                                            "Please enter the chat limit in digits between 1-9999"
                                        )}
                                        fullWidth
                                        autoComplete="off"
                                        type="number"
                                        style={{ width: "100%" }}
                                        inputProps={{ min: 1 }}
                                        onKeyDown={(e) => {
                                            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelChatLimitError &&
                                            OmniChannelChatLimitError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox" style={{ position: "relative" }}>
                                    <p>
                                        {" "}
                                        App secret <span>*</span>
                                    </p>
                                    <div style={{ position: 'relative', width: '100%' }} >
                                        <TextField
                                            placeholder="Enter app secret"
                                            variant="outlined"
                                            value={OmniChannelConfigurationAppSecret}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setOmniChannelConfigurationAppSecret,
                                                setOmniChannelAppSecretError,
                                                "Please enter the app secret"
                                            )}
                                            fullWidth
                                            type={ShowOmniChannelAppSecret ? "text" : "password"}
                                            style={{ width: "100%" }}
                                        />
                                        <span onClick={() => setShowOmniChannelAppSecret(!ShowOmniChannelAppSecret)}>
                                            {ShowOmniChannelAppSecret ? (
                                                <Visibility id="EyeIcon" />
                                            ) : (
                                                <VisibilityOff id="EyeIcon" />
                                            )}
                                        </span>
                                    </div>

                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelAppSecretError && OmniChannelAppSecretError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox" style={{ position: "relative" }}>
                                    <p>
                                        {" "}
                                        {TabMenu} Integration ID <span>*</span>
                                    </p>

                                    <div style={{ position: 'relative', width: '100%' }} >
                                        <TextField
                                            placeholder="Enter integration ID here"
                                            variant="outlined"
                                            value={OmniChannelConfigurationIntegrationId}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setOmniChannelConfigurationIntegrationId,
                                                setOmniChannelIntergrationIdError,
                                                "Please enter an integration ID"
                                            )}
                                            fullWidth
                                            type={ShowOmniChannelConfigurationIntegrationId ? "text" : "password"}
                                            style={{ width: "100%" }}
                                        />
                                        <span
                                            onClick={() =>
                                                setShowOmniChannelConfigurationIntegrationId(
                                                    !ShowOmniChannelConfigurationIntegrationId
                                                )
                                            }
                                        >
                                            {ShowOmniChannelConfigurationIntegrationId ? (
                                                <Visibility id="EyeIcon" />
                                            ) : (
                                                <VisibilityOff id="EyeIcon" />
                                            )}
                                        </span>
                                    </div>

                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelIntergrationIdError && OmniChannelIntergrationIdError
                                        }
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Maximum file size ( in MB ) <span>*</span>{" "}
                                    </p>

                                    <Select
                                        id="node-type"
                                        value={OmniChannelConfigurationFileSize}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setOmniChannelConfigurationFileSize,
                                            setOmniChannelFileSizeError,
                                            "Please select a suitable file size"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Allowed File Size
                                        </MenuItem>
                                        <MenuItem value="1">1</MenuItem>
                                        <MenuItem value="2">2</MenuItem>
                                        <MenuItem value="3">3</MenuItem>
                                        <MenuItem value="4">4</MenuItem>
                                        <MenuItem value="5">5</MenuItem>
                                        <MenuItem value="6">6</MenuItem>
                                        <MenuItem value="7">7</MenuItem>
                                        <MenuItem value="8">8</MenuItem>
                                        <MenuItem value="9">9</MenuItem>
                                        <MenuItem value="10">10</MenuItem>
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelFileSizeError && OmniChannelFileSizeError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Out of service hour message <span style={{ opacity: '0' }} >*</span>
                                    </p>

                                    <textarea id="MultiLineTextArea"
                                        type="text"
                                        value={OmniChannelConfigurationOutOfServiceMessage}
                                        onChange={(e) => setOmniChannelConfigurationOutOfServiceMessage(e.target.value)}
                                        style={{ width: "100%", fontSize: "15px" }}
                                        rows={7}
                                        placeholder="Enter Message"

                                    />
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Welcome message <span>*</span>
                                    </p>

                                    <textarea id="MultiLineTextArea"
                                        type="text"
                                        value={OmniChannelConfigurationWelcomeMessage}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setOmniChannelConfigurationWelcomeMessage,
                                            setOmniChannelWelcomeMessageError,
                                            "Please enter the welcome message"
                                        )}
                                        style={{ width: "100%", fontSize: "15px" }}
                                        rows={7}
                                        placeholder="Enter Message"

                                    />

                                    <p id="ErrorMessage" >
                                        {
                                            OmniChannelWelcomeMessageError && OmniChannelWelcomeMessageError
                                        }
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div
                                    style={{
                                        display: "grid",
                                        alignItems: "center",
                                        gridTemplateColumns: '45% 45%',
                                        width: "100%",
                                        columnGap: "20px",
                                        padding: "20px 5px",
                                    }}
                                >
                                    <p style={{ margin: '0px' }} >AI Agent </p>

                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                checked={OmniChannelConfigurationAiAgentSwitch}
                                                onChange={(e) => setOmniChannelConfigurationAiAgentSwitch(!OmniChannelConfigurationAiAgentSwitch)}
                                                color="primary"
                                            />
                                        }
                                    />
                                </div>

                                {OmniChannelConfigurationAiAgentSwitch == true && (
                                    <div id="InputBox">
                                        <p>
                                            {" "}
                                            AI Agent context <span>*</span>
                                        </p>

                                        <Select
                                            id="node-type"
                                            value={OmniChannelConfigurationAiAgentContextId}
                                            onChange={(e) => ErrorHandlingFunction(
                                                e,
                                                setOmniChannelConfigurationAiAgentContextId,
                                                setOmniChannelContextIdError,
                                                "Please select an Ai agent context"
                                            )}
                                            style={{ width: "100%" }}
                                        >
                                            <MenuItem value="0" disabled>
                                                Select AI Agent Context
                                            </MenuItem>
                                            {OmniChannelAIAgentContextList.map((agent, index) => {
                                                return (
                                                    <MenuItem key={index} value={agent.knowledge_base_uuid}>
                                                        {agent.doc_name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <p id="ErrorMessage" >
                                            {OmniChannelContextIdError && OmniChannelContextIdError}
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    columnGap: "10px",
                                    margin: "10px 0px",
                                }}
                            >
                                <hr
                                    style={{
                                        flex: 1,
                                        border: "none",
                                        borderTop: "1px solid lightgrey",
                                    }}
                                />
                                <p style={{ margin: 0, whiteSpace: "nowrap" }}>Time Conditions</p>
                                <hr
                                    style={{
                                        flex: 1,
                                        border: "none",
                                        borderTop: "1px solid lightgrey",
                                    }}
                                />
                            </div>

                            <>
                                {daysOfWeek.map((day) => (
                                    <div className="TimerRowBox" key={day}>
                                        <h3>{day} :</h3>
                                        <div id="Timebox">
                                            <p>Start Time</p>
                                            <TimePicker
                                                onChange={HandleTimeChange(day, "start")}
                                                value={dayjs(timeData[day].start, "HH:mm:ss")}
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                size="large"
                                            />
                                        </div>
                                        <div id="Timebox">
                                            <p>End Time</p>
                                            <TimePicker
                                                onChange={HandleTimeChange(day, "end")}
                                                value={dayjs(timeData[day].end, "HH:mm:ss")}
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                ))}

                            </>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: 'center',
                                columnGap: "15px",
                                paddingTop: '10px'
                            }}
                        >
                            <Button
                                id="CancelButtonFrontPage"
                                onClick={() => ClearOmniChannelModalStates()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                id="AddFlowButton"
                                variant="contained"
                            // onClick={HandleCreateOmniChannelConfiguration}
                            >
                                Add
                            </Button>
                        </div>
                    </Box>
                </form>
            </Modal>

            {/* Modal for creating the SMS  configuration */}
            <Modal open={CreateSmsConfigurationModal}
                onClose={() => HandleCancelButtonSmsModal()}
            >
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleAddSMSConfiguration();
                }}>

                    <Box sx={{ ...ivrModalStyle }} id="IvrConfigurationModal">
                        <h2>Add New SMS Configuration</h2>

                        <div id="AllInputBoxes">
                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter SMS broadcast name <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter name here"
                                        variant="outlined"
                                        value={SmsBroadcastName}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSmsBroadcastName,
                                            setSmsNameError,
                                            "Please enter configuration name"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    <p id="ErrorMessage" >
                                        {SmsNameError && SmsNameError}
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select the SMS template <span>*</span>
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SmsTemplate}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSmsTemplate,
                                            setSmsTemplateError,
                                            "Please select a SMS template"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Template
                                        </MenuItem>

                                        {smstemplateList.map((template) => (
                                            <MenuItem
                                                key={template.sms_template_uuid}
                                                value={template.sms_template_uuid}
                                            >
                                                {template.template_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {SmsTemplateError && SmsTemplateError}
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select the contact list <span>*</span>
                                    </p>
                                    <Select
                                        multiple
                                        id="node-type"
                                        value={selectedGroups.map((group) => group.grp_name)}
                                        displayEmpty // This shows placeholder when there are no values
                                        onChange={(event) => handleSelectChange(
                                            event,
                                            setSelectedGroups,
                                            setSmsContactListError,
                                            "Please select at least one contact list"
                                        )}
                                        style={{ width: "100%" }}
                                        renderValue={(selected) =>
                                            selected.length === 0 ? (
                                                <span style={{ color: "rgba(0, 0, 0, 0.54)" }}>Select A List</span>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexWrap: "nowrap",
                                                        gap: 0.5,
                                                        overflowX: "auto",
                                                        whiteSpace: "nowrap",
                                                        padding: "4px",
                                                    }}
                                                >
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )
                                        }
                                    >
                                        {groupList.map((group) => (
                                            <MenuItem key={group.grp_id} value={group.grp_name}>
                                                <Checkbox
                                                    checked={selectedGroups.some(
                                                        (selected) => selected.grp_name === group.grp_name
                                                    )}
                                                />
                                                <ListItemText primary={group.grp_name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {SmsContactListError && SmsContactListError}
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter the description for the campaign <span style={{ opacity: '0' }} >*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter name here"
                                        variant="outlined"
                                        value={SmsDescription}
                                        onChange={(e) => setSmsDescription(e.target.value)}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select the time zone <span>*</span>
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SmsTimezone}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSmsTimezone,
                                            setSmsTimeZoneError,
                                            "Please select a time-zone"
                                        )}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Time Zone
                                        </MenuItem>

                                        {TimeZonesList.map((zone, index) => {
                                            return (
                                                <MenuItem key={index} value={zone.timeZone}>
                                                    {zone.timeZone}{" "}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <p id="ErrorMessage" >
                                        {SmsTimeZoneError && SmsTimeZoneError}
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select frequency of the SMS (sms per second) <span>*</span>
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SmsFrequency}
                                        onChange={(e) => setSmsFrequency(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="0" disabled>
                                            Select Frequency
                                        </MenuItem>
                                        {SmsFrequenciesList.map((frequency) => (
                                            <MenuItem key={frequency} value={frequency}>
                                                {frequency}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Select schedule time <span>*</span>
                                    </p>
                                    <Select
                                        id="node-type"
                                        value={SmsScheduleTime}
                                        onChange={(e) => setSmsScheduleTime(e.target.value)}
                                        style={{ width: "100%" }}
                                    >
                                        <MenuItem value="start_now">Start Now</MenuItem>
                                        <MenuItem value="schedule">Schedule Date </MenuItem>
                                    </Select>
                                </div>

                                {/* {SmsScheduleTime == "start_now" && (
                                <div id="InputBox">
                                    <p>
                                        Select an end date <span>*</span>
                                    </p>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={
                                                    SmsEndDate ? dayjs(SmsEndDate, "YYYY-MM-DD") : null
                                                }
                                                onChange={(date) =>
                                                    setSmsEndDate(
                                                        date ? dayjs(date).format("YYYY-MM-DD") : null
                                                    )
                                                }
                                                format="YYYY-MM-DD"
                                            />
                                        </LocalizationProvider>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p>Select an end time <span> *</span> </p>
                                            <TimePicker
                                                onChange={(time, timeString) => {
                                                    setSmsEndTime(timeString);
                                                }}
                                                value={
                                                    SmsEndTime ? dayjs(SmsEndTime, "HH:mm:ss") : null
                                                }
                                                defaultOpenValue={dayjs("18:30:00", "HH:mm:ss")}
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                style={{ zIndex: 1301, marginLeft: "15px" }}
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )} */}
                            </div>

                            <div id="RowBox">
                                {SmsScheduleTime == "schedule" && (
                                    <div id="InputBox">
                                        <p>
                                            Select a start date <span>*</span>
                                        </p>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "flex-end",
                                            }}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    disablePast={true}
                                                    value={
                                                        SmsStartDate
                                                            ? dayjs(SmsStartDate, "YYYY-MM-DD")
                                                            : null
                                                    }
                                                    onChange={(date) =>
                                                        setSmsStartDate(
                                                            date ? dayjs(date).format("YYYY-MM-DD") : null
                                                        )
                                                    }
                                                    format="YYYY-MM-DD"
                                                />
                                            </LocalizationProvider>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <p>Select a start time <span> *</span> </p>
                                                <TimePicker
                                                    onChange={(time, timeString) => {
                                                        setSmsStartTime(timeString);
                                                    }}
                                                    value={
                                                        SmsStartTime ? dayjs(SmsStartTime, "HH:mm:ss") : null
                                                    }
                                                    defaultOpenValue={dayjs("09:30:00", "HH:mm:ss")}
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                    style={{ zIndex: 1301, marginLeft: "15px" }}
                                                    size="large"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div id="InputBox">
                                    <p>
                                        Select an end date <span>*</span>
                                    </p>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-end",
                                        }}
                                    >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                disablePast={true}
                                                value={
                                                    SmsEndDate ? dayjs(SmsEndDate, "YYYY-MM-DD") : null
                                                }
                                                onChange={(date) =>
                                                    setSmsEndDate(
                                                        date ? dayjs(date).format("YYYY-MM-DD") : null
                                                    )
                                                }
                                                format="YYYY-MM-DD"
                                            />
                                        </LocalizationProvider>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            }}
                                        >
                                            <p>Select an end time <span> *</span> </p>
                                            <TimePicker
                                                onChange={(time, timeString) => {
                                                    setSmsEndTime(timeString);
                                                }}
                                                value={
                                                    SmsEndTime ? dayjs(SmsEndTime, "HH:mm:ss") : null
                                                }
                                                defaultOpenValue={dayjs("18:30:00", "HH:mm:ss")}
                                                getPopupContainer={(trigger) => trigger.parentNode}
                                                style={{ zIndex: 1301, marginLeft: "15px" }}
                                                size="large"
                                            />
                                        </div>
                                    </div>
                                    <p id="ErrorMessage" >
                                        {SmsEndDateTimeError && SmsEndDateTimeError}
                                    </p>
                                </div>
                            </div>

                            <div id="RowBox">
                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter Reply <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter reply"
                                        variant="outlined"
                                        value={smsReply}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setSmsReply,
                                            (setSmsReplyError || setSmsReplyListError),
                                            "Please enter a reply"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    <p id="ErrorMessage" >
                                        {SmsReplyError && SmsReplyError}
                                    </p>
                                </div>

                                <div id="InputBox">
                                    <p>
                                        {" "}
                                        Enter the Expected Reply <span>*</span>
                                    </p>
                                    <TextField
                                        placeholder="Enter Expected reply"
                                        variant="outlined"
                                        value={expectedSmsReply}
                                        onChange={(e) => ErrorHandlingFunction(
                                            e,
                                            setExpectedSmsReply,
                                            setSmsExpectedReplyError,
                                            "Please enter an expected reply"
                                        )}
                                        fullWidth
                                        style={{ width: "100%" }}
                                    />
                                    <p id="ErrorMessage" >
                                        {SmsExpectedReplyError && SmsExpectedReplyError}
                                    </p>
                                </div>
                            </div>


                            <Button
                                style={{
                                    backgroundColor: "#746df7",
                                    color: "white",
                                    width: "150px",
                                    transform: "scale(.9)",
                                    alignSelf: "center",
                                }}
                                variant="contained"
                                onClick={() => {
                                    handleAddReplies();
                                }}
                            >
                                Add Reply
                            </Button>

                            <div
                                style={{ marginTop: "20px", width: "80%", alignSelf: "center" }}
                            >
                                <table
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                fontWeight: "bold",
                                                borderBottom: "2px solid #ddd",
                                            }}
                                        >
                                            <th style={{ textAlign: "center", padding: "8px" }}>
                                                Reply
                                            </th>
                                            <th style={{ textAlign: "center", padding: "8px" }}>
                                                Expected Reply
                                            </th>
                                            <th style={{ textAlign: "center", padding: "8px" }}>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {repliesList.length > 0 ? (
                                            repliesList.map((item, index) => (
                                                <tr key={index} style={{}}>
                                                    <td style={{ padding: "8px", textAlign: "center" }}>
                                                        {item.reply}
                                                    </td>
                                                    <td style={{ padding: "8px", textAlign: "center" }}>
                                                        {item.expectedReply}
                                                    </td>
                                                    <td style={{ padding: "8px", textAlign: "center" }}>
                                                        <Tooltip title="Delete" placement="bottom">
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleDeleteReply(index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            ))

                                        ) : (
                                            <>
                                                {SmsReplyListError ? (
                                                    <tr>
                                                        <td
                                                            colSpan={2} // Merges the first two columns
                                                            style={{
                                                                padding: "8px",
                                                                textAlign: "center",
                                                                fontStyle: "italic",
                                                                color: "red",
                                                            }}
                                                        >
                                                            -- Please enter at least one pair of reply and expected reply --
                                                        </td>
                                                        <td style={{ padding: "8px", textAlign: "center" }}>
                                                            <Tooltip title="Delete" placement="bottom">
                                                                <IconButton color="error" disabled>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr>
                                                        <td style={{ padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                            -- Reply --
                                                        </td>
                                                        <td style={{ padding: "8px", textAlign: "center", fontStyle: "italic" }}>
                                                            -- Expected reply --
                                                        </td>
                                                        <td style={{ padding: "8px", textAlign: "center" }}>
                                                            <Tooltip title="Delete" placement="bottom">
                                                                <IconButton color="error" disabled>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>

                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: 'center',
                                columnGap: "15px",
                                paddingTop: '10px'
                            }}
                        >
                            <Button
                                id="CancelButtonFrontPage"
                                onClick={() => HandleCancelButtonSmsModal()}
                            >
                                Cancel
                            </Button>

                            <Button
                                type="submit"
                                id="AddFlowButton"
                                variant="contained"
                            // onClick={() => { handleAddSMSConfiguration() }}
                            >
                                Add
                            </Button>
                        </div>
                    </Box>
                </form>
            </Modal >
        </>
    );
};

export default FrontPage;
