import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CustomNode from "./CustomNode";
import IVRNode from "./IVRNode";
import VoiceBotNode from "./VoiceBotNode";
import CreatedNewIVR from "./CreatedNewIVR";
import NewSmsBroadcast from "./NewSmsBroadcast";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  InputLabel,
  Tooltip,
  Grid,
  Input,
  Switch,
  styled,
} from "@mui/material";

import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  ReactFlowProvider,
  Handle,
  reconnectEdge
} from "reactflow";
import "reactflow/dist/style.css";
import "./SideDrawer.css";
import "./WorkflowBuilder.css";
import {
  Apartment,
  CallEnd,
  Delete,
  FollowTheSigns,
  KeyboardBackspace,
  LowPriority,
  MessageOutlined,
  MicNone,
  NearMe,
  NumbersSharp,
  PhoneCallback,
  RecordVoiceOver,
  SupportAgent,
  TransferWithinAStation,
  Voicemail,
  Api,
  MarkUnreadChatAlt,
  QuestionAnswer,
  TouchApp,
  MarkChatRead,
  ConnectWithoutContact,
  SmsFailed,
  WhatsApp,
} from "@mui/icons-material";
import {
  addChatbotCase,
  addIvrOption,
  DeleteIvrOptionById,
  DeleteNodeForVoiceBot,
  EditVoiceBotNode,
  GetIVROptionMenuDetails,
  getVoiceBotCaseById,
  getWhatsappConfiguration_API,
  KnowledgeBaseList,
  ShowExtensionsList,
  showIvrConfigurationList,
  ShowPromptList,
  ShowQueueListForIvr,
  showVoiceBotConfigurationList,
  updateIvrOption,
  updateChatbotCase,
  DeleteChatbotCase,
  getQueuesList,
} from "./utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

const nodeTypes = {
  custom: CustomNode,
  IVRNode: IVRNode,
  CreatedNewIVR: CreatedNewIVR,
  VoiceBotNode: VoiceBotNode,
  NewSmsBroadcast: NewSmsBroadcast,
};

const CreateNodeModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  bgcolor: "#F6F6F6",
  borderRadius: "4px",
  outline: "0px",
};

const WorkflowBuilder = ({ accessToken, domainUUID }) => {
  // const WorkflowBuilder = ({accessToken, domainUUID}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const edgeReconnectSuccessful = useRef(true);

  const { configurationName, configurationId, channel, condition } =
    location.state || {};

  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_API_URL_CASE_FACEBOOK = process.env.REACT_APP_API_URL_CASE_FACEBOOK;

  const REACT_APP_API_URL_FLOW = process.env.REACT_APP_API_URL_FLOW;
  const REACT_APP_API_URL_CASE_CALL_CENTER =
    process.env.REACT_APP_API_URL_CASE_CALL_CENTER;

  // const domainUUID = "50f0827c-d18f-11ee-8379-0a95ea71a99c";
  // const accessToken =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJUZW5hbnQiLCJleHAiOjE3Mjg3MTkyNzEsImlhdCI6MTcyODU0NjQ3MSwiaXNzIjoiYXV0aC1zZXJ2aWNlIiwibmJmIjoxNzI4NTQ2NDcxLCJzdWIiOiI1MGYwODI3Yy1kMThmLTExZWUtODM3OS0wYTk1ZWE3MWE5OWMifQ.ARe-PVwOhx6fvP4xWVJQiS5mUNCo_yCqfJ3kihvmKx8";

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const [selectedNodeType, setSelectedNodeType] = useState("");
  const [configurationList, setConfigurationList] = useState("");
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeName, setNodeName] = useState("");
  const [nodeData, setNodeData] = useState("");
  const [conditionAPI, setConditionAPI] = useState("");
  const [method, setMethod] = useState("");
  const [payloadType, setPayloadType] = useState("");
  const [payload, setPayload] = useState("");
  const [queue, setQueue] = useState("");
  const [queueList, setQueueList] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [buttonList, setButtonList] = useState([]);
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    if (condition) {
      handleRestoreLayout();
    }
  }, [condition]);
  const handleAddButton = () => {
    if (
      selectedImage == "layout_1" &&
      buttonList.length > 2 &&
      channel == "whatsapp"
    ) {
      toast.info("Please Change the Layout!", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    if (buttonText.trim() !== "") {
      setButtonList([...buttonList, buttonText]);
      setButtonText("");
    }
  };

  const handleDeleteButton = (index) => {
    setButtonList(buttonList.filter((_, i) => i !== index));
  };

  const onNodesChange = (changes) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));
  // const onConnect = (connection) => setEdges((eds) => addEdge(connection, eds));
  const onConnect = (connection) => {
    const isSourceHandleAlreadyConnected = edges.some(
      (edge) => edge.source === connection.source && edge.sourceHandle === connection.sourceHandle
    );

    if (isSourceHandleAlreadyConnected) {
      // Prevent the new connection if the source handle is already connected to another node
      return;
    } else {
      setEdges((eds) => addEdge(connection, eds));
    }
  };

  const onReconnectStart = () => {
    edgeReconnectSuccessful.current = false;
  };

  // Handles reconnection of an edge
  const onReconnect = (oldEdge, newConnection) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  };

  // Handles deletion if reconnection fails
  const onReconnectEnd = (_, edge) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }
    edgeReconnectSuccessful.current = true;
  };


  const fetchQueuesList = async () => {
    const result = await getQueuesList(accessToken, domainUUID);

    if (result.ResponseCode === 200) {
      let filteredResult = result.Queue; // Initialize with full queue

      // Filter based on channel
      if (channel === "whatsapp") {
        filteredResult = result.Queue.filter((item) => item.map_with === "whatsapp");
      } else if (channel === "telegram") {
        filteredResult = result.Queue.filter((item) => item.map_with === "telegram");
      }

      setQueueList(filteredResult);
    } else {
      setQueueList([]);
    }
  };


  const PublishChanges = () => {
    if (channel == "" || configurationId === "") {
      toast.info("Please select all the required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    const flow = { nodes, edges };

    // console.log(flow);

    localStorage.setItem("flow", JSON.stringify(flow));
    const flowvalue = localStorage.getItem("flow");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      configuration_id: configurationId,
      flow: flowvalue,
      channel:
        channel === "IVR"
          ? `${channel}_${configurationId}`
          : channel === "smsBroadcast"
            ? "smsBroadcastPublish"
            : channel,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/publishChanges?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == false) {
          toast.info(result.message, {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (result.status == true) {
          toast.success("Changes Published", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error("Error in Publishing Data", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  // <-----------For Retrieving the flow ------->
  useEffect(() => {
    if (configurationId != "") {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const raw = JSON.stringify({
        domain_uuid: domainUUID,
        configuration_id: configurationId,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${REACT_APP_API_URL_FLOW}/ccaas/workflow/v1/getChanges?user_uuid=${domainUUID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status == true) {
            const Flow = result.data[0].flow;

            const localflow = JSON.parse(Flow);

            if (localflow) {
              const rehydratedNodes = localflow.nodes.map((node) => {
                if (
                  node.type === "message" ||
                  node.type === "chat" ||
                  node.type === "button" ||
                  node.type === "custom"
                ) {
                  return {
                    ...node,
                    data: {
                      ...node.data,
                      label: (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "250px",
                            rowGap: "6px",
                          }}
                        >
                          <h2
                            style={{
                              fontWeight: "400",
                              fontSize: "16px",
                              display: "flex",
                              alignItems: "center",
                              margin: "0px",
                              textTransform: "capitalize",
                              color: "#094e6c",
                              borderBottom: "1px #006d77 solid",
                              paddingBottom: "3px",
                            }}
                          >
                            {node.type === "message"
                              ? "Message"
                              : node.type === "chat"
                                ? "Chat"
                                : "Button"}
                          </h2>
                          <h3
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              margin: "0px",
                              color: "#161a1d",
                            }}
                          >
                            Name:
                            <span style={{ fontWeight: "400" }}>
                              {node.data.nodeName}
                            </span>
                          </h3>
                          <p
                            style={{
                              border: "1px lightgrey solid",
                              borderRadius: "4px",
                              padding: "6px",
                              fontWeight: "400",
                              lineHeight: "24px",
                              fontSize: "16px",
                              fontStyle: "italic",
                              backgroundColor: "#E9F6E6",
                              margin: "0px 5px 0px 0px",
                              textAlign: "justify",
                              width: "95%",
                            }}
                          >
                            {node.data.ConditionData}
                          </p>

                          {node.type === "button" ||
                            (node.type === "custom" && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginTop: "10px",
                                }}
                              >
                                { }
                                {node.data.buttonList.map(
                                  (buttonLabel, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            backgroundColor: "#006d77",
                                            borderRadius: "50%",
                                            marginRight: "8px",
                                          }}
                                        />
                                        <span>{buttonLabel}</span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            ))}

                          {node.type !== "button" && node.type !== "custom" && (
                            <>
                              <Handle
                                type="source"
                                position="right"
                                id="sourceHandle"
                                style={{
                                  background: "#333333",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  zIndex: 100,
                                }}
                              />
                              <Handle
                                type="target"
                                position="left"
                                id="targetHandle"
                                style={{
                                  background: "#333333",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  zIndex: 100,
                                }}
                              />
                            </>
                          )}
                        </div>
                      ),
                    },
                  };
                } else if (node.type === "IVRNode") {
                  return restoreIVRNode(node);
                } else if (node.type === "NewIVR") {
                  return restoreNewIVRNode(node);
                } else if (node.type === "CreatedNewIVR") {
                  return restoreCreatedNewIVR(node);
                } else if (node.type === "VoiceBotNode") {
                  return restoreVoiceBotNode(node);
                } else if (node.type === "NewSmsBroadcast") {
                  return restoreNewSmsBroadcastNode(node);
                } else if (node.type === "SmsNode") {
                  return restoreSmsNode(node);
                }
                return node;
              });

              setNodes(rehydratedNodes);
              setEdges(localflow.edges);
            }
          }
        })
        .catch((error) => console.error(error));
    }
  }, [configurationId]);

  const restoreIVRNode = (node) => {
    if (node.type === "IVRNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {node.destinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name :{" "}
                  <span style={{ fontWeight: "400" }}>
                    {" "}
                    {node.data.nodeName}{" "}
                  </span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type :{node.destinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {node.destinationName}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreNewIVRNode = (node) => {
    if (node.type === "NewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.destinationType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name:
                <span style={{ fontWeight: "400" }}>{node.data.nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Type:
                {node.destinationType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Name: <br />
                {node.destinationName}
              </p>

              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreCreatedNewIVR = (node) => {
    if (node.type === "CreatedNewIVR") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  IVR
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {node.name}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Description :{node.ivrDescription}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreVoiceBotNode = (node) => {
    // console.log(node, "from restore");
    if (node.type === "VoiceBotNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {node.conditionType === "message"
                  ? "Message"
                  : node.conditionType === "welcome_message"
                    ? "Welcome Message"
                    : node.conditionType === "connect_agent"
                      ? "Connect To Agent"
                      : node.conditionType === "user_input"
                        ? "User Input"
                        : "Invalid Message"}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Type :{node.conditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data :{node.conditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {node.data.buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreNewSmsBroadcastNode = (node) => {
    if (node.type === "NewSmsBroadcast") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                SMS Broadcast
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}>{node.name} </span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Description :{node.SmsDescription}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {node.data.buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
        },
      };
    }
    return node;
  };

  const restoreSmsNode = (node) => {
    if (node.type === "SmsNode") {
      return {
        ...node,
        data: {
          ...node.data,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                API
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :{" "}
                <span style={{ fontWeight: "400" }}>{node.data.nodeName} </span>
              </h3>

              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />

              {/* <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Description :{node.SmsDescription}
              </p> */}
            </div>
          ),
        },
      };
    }
    return node;
  };

  const getVoiceMailIdById = (Id) => {
    const name = destinationNameList.find(
      (voicemail) => voicemail.extension_uuid === Id
    );
    return name ? name.voicemail_id : "";
  };

  const handleEditNode = () => { };

  const EditCreatedNewIVR = () => { };

  const deleteNode = (id) => {
    // <-----------to be used later for dynamic deletion ------------->
    // if (selectedNodeType == "CreatedNewIVR") {
    //   DeleteCreatedNewIVR();
    //   return;
    // }
    if (selectedNodeType == "NewIVR") {
      DeleteNewIVR(id);
      return;
    }
    if (selectedNodeType == "IVRNode") {
      DeleteIVRNode(id);
      return;
    }

    // <------------for temporary deletion ------------>
    // if (channel == "IVR") {
    //   handleDeleteNodeForIVR(id);
    //   return;
    // }

    if (channel == "voiceBot") {
      handleDeleteNodeForVoiceBot(id);
      return;
    }

    setUpdateMode(false);
    setNodeName("");
    setNodeData("");
    setSelectedNodeType("");
    setButtonList([]);
    setQueue("1");
    setSelectedImage("");
  };

  const DeleteCreatedNewIVR = () => { };
  const DeleteNewIVR = async (id) => {
    const result = await GetIVROptionMenuDetails(
      accessToken,
      domainUUID,
      configurationId,
      id
    );

    if (result.Status == "1") {
      const ivr_menu_option_uuid = result.IvrMenuList[0].ivr_menu_option_uuid;

      const DeleteResult = await DeleteIvrOptionById(
        accessToken,
        domainUUID,
        ivr_menu_option_uuid
      );

      if (DeleteResult.Status == "1") {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) =>
          eds.filter((edge) => edge.source !== id && edge.target !== id)
        );
        setUpdateMode(false);
        setOpenCreateIvrNodeModal(false);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };
  const DeleteIVRNode = async (id) => {
    const result = await GetIVROptionMenuDetails(
      accessToken,
      domainUUID,
      configurationId,
      id
    );

    if (result.Status == "1") {
      const ivr_menu_option_uuid = result.IvrMenuList[0].ivr_menu_option_uuid;

      const DeleteResult = await DeleteIvrOptionById(
        accessToken,
        domainUUID,
        ivr_menu_option_uuid
      );

      if (DeleteResult.Status == "1") {
        setNodes((nds) => nds.filter((node) => node.id !== id));
        setEdges((eds) =>
          eds.filter((edge) => edge.source !== id && edge.target !== id)
        );
        setUpdateMode(false);
        setOpenCreateIvrNodeModal(false);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };

  const handleDeleteNodeForVoiceBot = async (id) => { };


  const handleNodeClick = (event, node) => {
    console.log(node);
    if (channel == "IVR" && node.type != "CreatedNewIVR") {
      setOpenCreateIvrNodeModal(true);
    }
    if (channel == "whatsapp") {
      setOpenCreateWhatsAppNodeModal(true);
    }
    if (channel == "telegram") {
      setOpenCreateTelegramNodeModal(true)
    }

    if (channel == "voiceBot") {
      setOpenCreateVoicebotNodeModal(true);
    }
    if (channel == "smsBroadcast") {
      setOpenCreateSmsbotNodeModal(true);
      setNodeName(node.data.nodeName);
      setSmsbotApiCurlName(node.Curl);
      return;
    }
    setNodeName(node.data.nodeName);
    setSelectedNodeId(node.id);
    setConditionData(node.data.ConditionData);
    setUpdateMode(true);

    if (node.type == "custom") {
      setWhatsappNodeConditionType("button");
      setTelegramNodeConditionType("button")
      setSelectedImage(node.selectedImage);
      setButtonList(node.data.buttonList);

    } else if (node.type == "VoiceBotNode") {
      setVoicebotNodeConditionType(node.conditionType);
      getParticularVoiceBotCaseById(node.id);
    } else if (node.type == "CreatedNewIVR") {
      setSelectedNodeType(node.type);
      getDetailsOfCreatedIVR(node.id);
    } else if (node.type == "IVRNode") {
      setSelectedNodeType(node.type);
      getDetailsOfIVRNode(node.id, node.destinationType);
    } else if (node.type == "NewIVR") {
      setSelectedNodeType(node.type);
      getDetailsOfNewIVR(node.id, node.destinationType);
    } else {
      setWhatsappNodeConditionType(node.type);
      setTelegramNodeConditionType(node.type)
      setSelectedNodeType(node.type);
      setQueue(node.data.queue);
      setSelectedImage(node.selectedImage);
      setButtonList(node.data.buttonList);
    }
  };


  // const handleNodeClick = (event, node) => {
  //   // console.log(node);
  //   if (channel == "IVR") {
  //     setOpenCreateIvrNodeModal(true);
  //   }
  //   if (channel == "whatsapp") {
  //     setOpenCreateWhatsAppNodeModal(true);
  //   }
  //   if (channel == "telegram") {
  //     setOpenCreateTelegramNodeModal(true)
  //   }

  //   if (channel == "voiceBot") {
  //     setOpenCreateVoicebotNodeModal(true);
  //   }
  //   if (channel == "smsBroadcast") {
  //     setOpenCreateSmsbotNodeModal(true);
  //     setNodeName(node.data.nodeName);
  //     setSmsbotApiCurlName(node.Curl);
  //     return;
  //   }

  //   setSelectedImage(node.selectedImage);
  //   setConditionData(node.data.ConditionData);
  //   setSelectedNodeId(node.id);
  //   setButtonList(node.data.buttonList);
  //   setUpdateMode(true);
  //   setNodeName(node.data.nodeName);

  //   if (node.type == "custom") {
  //     setWhatsappNodeConditionType("button");
  //     setTelegramNodeConditionType("button")
  //   } else if (node.type == "VoiceBotNode") {
  //     setVoicebotNodeConditionType(node.conditionType);
  //     getParticularVoiceBotCaseById(node.id);
  //   } else if (node.type == "CreatedNewIVR") {
  //     setSelectedNodeType(node.type);
  //     getDetailsOfCreatedIVR(node.id);
  //   } else if (node.type == "IVRNode") {
  //     setSelectedNodeType(node.type);
  //     getDetailsOfIVRNode(node.id, node.destinationType);
  //   } else if (node.type == "NewIVR") {
  //     setSelectedNodeType(node.type);
  //     getDetailsOfNewIVR(node.id, node.destinationType);
  //   } else {
  //     setWhatsappNodeConditionType(node.type);
  //     setTelegramNodeConditionType(node.type)
  //     setSelectedNodeType(node.type);
  //     setQueue(node.data.queue);
  //   }
  // };

  const getDetailsOfCreatedIVR = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/getIvrListUuid/${domainUUID}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setNodeName(result.IvrList[0].ivr_menu_name);
      })
      .catch((error) => console.error(error));
  };
  const [IvrMenuList, setIvrMenuList] = useState([]);

  const getParticularVoiceBotCaseById = async (nodeId) => {
    const result = await getVoiceBotCaseById(
      accessToken,
      domainUUID,
      configurationId,
      nodeId
    );
    setVoiceBotLanguage(result.VoiceBotCase[0].language);
    setVoiceBotUserInput(result.VoiceBotCase[0].user_input_type);
    setVoiceBotVoiceType(result.VoiceBotCase[0].voice_type);
    setQueueForVoiceBot(result.VoiceBotCase[0].queue_uuid);
    setIDforVoiceBot(result.VoiceBotCase[0].id);
  };

  const handleSaveLayout = () => {
    const LocalFlow = { nodes, edges };
    localStorage.setItem("LocalFlow", JSON.stringify(LocalFlow));
  };

  const handleRestoreLayout = () => {
    const LocalFlow = JSON.parse(localStorage.getItem("LocalFlow"));

    if (LocalFlow) {
      const rehydratedNodes = LocalFlow.nodes.map((node) => {
        if (node.type === "NewSmsBroadcast") {
          return restoreNewSmsBroadcastNode(node);
        } else if (node.type === "SmsNode") {
          return restoreSmsNode(node);
        } else if (node.type === "IVRNode") {
          return restoreIVRNode(node);
        } else if (node.type === "NewIVR") {
          return restoreNewIVRNode(node);
        } else if (node.type === "CreatedNewIVR") {
          return restoreCreatedNewIVR(node);
        } else if (node.type === "VoiceBotNode") {
          return restoreVoiceBotNode(node);
        } else if (
          node.type === "message" ||
          node.type === "chat" ||
          node.type === "button" ||
          node.type === "custom"
        ) {
          return {
            ...node,
            data: {
              ...node.data,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    {node.type === "message"
                      ? "Message"
                      : node.type === "chat"
                        ? "Chat"
                        : "Button"}
                  </h2>
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name:
                    <span style={{ fontWeight: "400" }}>
                      {node.data.nodeName}
                    </span>
                  </h3>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    {node.data.ConditionData}
                  </p>
                  {node.type === "button" ||
                    (node.type === "custom" && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        { }
                        {node.data.buttonList.map((buttonLabel, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginBottom: "4px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  backgroundColor: "#006d77",
                                  borderRadius: "50%",
                                  marginRight: "8px",
                                }}
                              />
                              <span>{buttonLabel}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}

                  {node.type !== "button" && node.type !== "custom" && (
                    <>
                      <Handle
                        type="source"
                        position="right"
                        id="sourceHandle"
                        style={{
                          background: "#333333",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          zIndex: 100,
                        }}
                      />
                      <Handle
                        type="target"
                        position="left"
                        id="targetHandle"
                        style={{
                          background: "#333333",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          zIndex: 100,
                        }}
                      />
                    </>
                  )}
                </div>
              ),
            },
          };
        }
        return node;
      });

      setNodes(rehydratedNodes);
      setEdges(LocalFlow.edges);
    }
  };

  // <--------------------For IVR ------------------->

  const [promptList, setPromptList] = useState("");
  const [destinationNameList, setDestinationNameList] = useState("");
  const [IDforVoiceBot, setIDforVoiceBot] = useState("");

  const getIvrList = async () => {
    const result = await showIvrConfigurationList(accessToken, domainUUID);
    if (result.Status == "1") {
      setConfigurationList(result.IvrList);
      setDestinationNameList(result.IvrList);
    }
  };

  useEffect(() => {
    if (configurationId == "createIvr") {
      showPromptList();
    }
  }, [configurationId]);

  const showPromptList = async () => {
    const result = await ShowPromptList(accessToken, domainUUID);
    if (result.Status == "1") {
      setPromptList(result.prompt_list);
      setDestinationNameList(result.prompt_list);
    }
  };

  const showQueueListForIvr = async () => {
    const result = await ShowQueueListForIvr(accessToken, domainUUID);
    setDestinationNameList(result);

    const filteredResultforVoice = result.filter(
      (item) => item.is_enable === "true"
    );
    setQueueListforVoiceBot(filteredResultforVoice);
  };

  const FetchExtensionList = async () => {
    const result = await ShowExtensionsList(accessToken, domainUUID);

    const filteredResult = result.filter(
      (item) => item.voicemail_enable === "true"
    );
    setDestinationNameList(filteredResult);
  };

  const VoiceBotConfigurationList = async () => {
    const result = await showVoiceBotConfigurationList(accessToken, domainUUID);
    if (result.Status == "1") {
      setDestinationNameList(result.VoiceBotConfiguration);
    }
  };

  const showKnowledgeBaseList = async () => {
    const result = await KnowledgeBaseList(accessToken, domainUUID);
    if (result.Status == "1") {
      setDestinationNameList(result.KnowledgeBase);
    }
  };

  const getFileLocationById = (Id) => {
    const name = promptList.find(
      (fileLocation) => fileLocation.pmt_uuid === Id
    );
    return name ? name.file_location : "";
  };

  const getVoiceMailIdByName = (voicename) => {
    const name = destinationNameList.find(
      (voicemail) => voicemail.extension === voicename
    );
    return name ? name.extension_uuid : "";
  };

  const getQueueNameById = (Id) => {
    const name = queueList.find((queue) => queue.queue_uuid === Id);
    return name ? name.name : "";
  };

  // <----------for background --------->
  const [backgroundVariant, setBackgroundVariant] = useState("cross");

  const handleVariantChange = (event) => {
    setBackgroundVariant(event.target.value);
  };

  // Add nodes button
  const handleModalForAddNode = () => {

    if (channel == "IVR") {
      setOpenCreateIvrNodeModal(true);
    }
    if (channel == "whatsapp") {
      setOpenCreateWhatsAppNodeModal(true);
    }
    if (channel == "voiceBot") {
      setOpenCreateVoicebotNodeModal(true);
    }
    if (channel == "smsBroadcast") {
      setOpenCreateSmsbotNodeModal(true);
    }
    if (channel == "telegram") {
      setOpenCreateTelegramNodeModal(true)
    }
  };

  // <-----------------for voicebot----------------->
  const [voiceBotLanguage, setVoiceBotLanguage] = useState("en-US");
  const [voiceBotVoiceType, setVoiceBotVoiceType] = useState("female");
  const [voiceBotUserInput, setVoiceBotUserInput] = useState("");
  const [queueListforVoiceBot, setQueueListforVoiceBot] = useState("");
  const [queueForVoiceBot, setQueueForVoiceBot] = useState("");





  // STATES FOR CREATING IVR NODE MODAL
  const [OpenCreateIvrNodeModal, setOpenCreateIvrNodeModal] = useState(false);
  const [IvrNodeDestinationType, setIvrNodeDestinationType] = useState("IVR"); // Default selected menu
  const [IvrNodeDestinationName, setIvrNodeDestinationName] = useState(""); // Track the selected sub-menu
  const [apicurl, setApiCurl] = useState("");

  const IvrDestinationTypeItems = [
    { name: "IVR", icon: <RecordVoiceOver id="MenuIcon" /> },
    { name: "QUEUE", icon: <TransferWithinAStation id="MenuIcon" /> },
    { name: "PLAYBACK", icon: <LowPriority id="MenuIcon" /> },
    { name: "VOICEMAIL", icon: <Voicemail id="MenuIcon" /> },
    { name: "HANGUP", icon: <CallEnd id="MenuIcon" /> },
    { name: "CALLBACK", icon: <PhoneCallback id="MenuIcon" /> },
    { name: "VOICEBOT", icon: <MicNone id="MenuIcon" /> },
    { name: "AIAGENT", icon: <SupportAgent id="MenuIcon" /> },
    { name: "API", icon: <Apartment id="MenuIcon" /> },
  ];

  useEffect(() => {
    if (IvrNodeDestinationType == "IVR") {
      getIvrList();
    }
    if (IvrNodeDestinationType == "QUEUE") {
      showQueueListForIvr();
    }
    if (IvrNodeDestinationType == "PLAYBACK") {
      showPromptList();
    }
    if (IvrNodeDestinationType == "VOICEMAIL") {
      FetchExtensionList();
    }
    if (IvrNodeDestinationType == "HANGUP") {
      setDestinationNameList([
        {
          HangupId: "hangup",
          HangupName: "Hangup",
        },
      ]);
    }
    if (IvrNodeDestinationType == "CALLBACK") {
      setDestinationNameList([
        {
          CallbackId: "callback",
          CallbackName: "Callback",
        },
      ]);
    }
    if (IvrNodeDestinationType == "VOICEBOT") {
      VoiceBotConfigurationList();
    }
    if (IvrNodeDestinationType == "AIAGENT") {
      showKnowledgeBaseList();
    }
    if (IvrNodeDestinationType == "API") {
      setDestinationNameList([]);
    }
  }, [IvrNodeDestinationType]);

  const HandleIvrNodeDestinationTypeClick = (type) => {
    setIvrNodeDestinationType(type);
    setIvrNodeDestinationName(""); // Clear sub-menu selection on new menu click
  };

  const HandleIvrNodeDestinationNameClick = (name) => {
    setIvrNodeDestinationName(name);
  };

  const getDestinationNameById = (Id) => {
    if (IvrNodeDestinationType == "IVR") {
      const name = destinationNameList.find(
        (destination) => destination.ivr_menu_uuid === Id
      );
      return name ? name.ivr_menu_name : "";
    }
    if (IvrNodeDestinationType == "QUEUE") {
      const name = destinationNameList.find(
        (destination) => destination.call_center_queue_uuid === Id
      );
      return name ? name.queue_name : "";
    }
    if (IvrNodeDestinationType == "PLAYBACK") {
      const name = destinationNameList.find(
        (destination) => destination.pmt_uuid === Id
      );
      return name ? name.prompt_name : "";
    }
    if (IvrNodeDestinationType == "VOICEMAIL") {
      const name = destinationNameList.find(
        (destination) => destination.extension_uuid === Id
      );
      return name ? name.extension : "";
    }
    if (IvrNodeDestinationType == "HANGUP") {
      const name = destinationNameList.find(
        (destination) => destination.HangupId === Id
      );
      return name ? name.HangupName : "";
    }
    if (IvrNodeDestinationType == "CALLBACK") {
      const name = destinationNameList.find(
        (destination) => destination.CallbackId === Id
      );
      return name ? name.CallbackName : "";
    }
    if (IvrNodeDestinationType == "VOICEBOT") {
      const name = destinationNameList.find(
        (destination) => destination.configuration_uuid === Id
      );
      return name ? name.configuration_name : "";
    }
    if (IvrNodeDestinationType == "AIAGENT") {
      const name = destinationNameList.find(
        (destination) => destination.knowledge_base_uuid === Id
      );
      return name ? name.doc_name : "";
    }
  };

  const HandleIvrCreateNodeModalCancelButton = () => {
    if (updateMode) {
      setUpdateMode(false);
    }
    setIvrNodeDestinationType("IVR");
    setIvrNodeDestinationName("");
    setApiCurl("");
    setOpenCreateIvrNodeModal(false);
    setNodeName("");
  };

  const HandleIvrCreateNodeModalButton = async () => {
    if (nodeName == "" && IvrNodeDestinationName != "") {
      toast.info("Please Enter the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (IvrNodeDestinationName == "" && nodeName != "") {
      toast.info("Please Select Destination Name", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (nodeName == "" || IvrNodeDestinationName == "") {
      toast.info("Missing Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await addIvrOption(
      accessToken,
      domainUUID,
      configurationId,
      IVR_MENU_OPTION_PARAM,
      nodeName
    );

    if (result.Status === "0") {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
    } else if (result.Status === "1") {
      const NodeId = result.NodeId;
      // if destination type is ivr then make IVR type node(with 12 source nodes) --->IVRNode
      if (IvrNodeDestinationType == "IVR") {
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const newNodePosition = lastNode
          ? {
            x: lastNode.position.x + 300, // Offset horizontally from the last node
            y: lastNode.position.y, // Offset vertically from the last node
          }
          : { x: 100, y: 100 };

        const newNode = {
          id: `${NodeId}`,
          type: "IVRNode",
          style: { width: "fit-content" },
          position: newNodePosition,
          destinationType: IvrNodeDestinationType,
          destinationName: getDestinationNameById(IvrNodeDestinationName),

          data: {
            nodeName,
            label: (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "89% 10%",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: "4px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "5px",
                    alignItems: "flex-start",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    {IvrNodeDestinationType}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Type : {IvrNodeDestinationType}
                  </p>
                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Destination Name :<br />
                    {getDestinationNameById(IvrNodeDestinationName)}
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "3px",
                  }}
                >
                  {[
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "*",
                    "#",
                  ].map((value, index) => (
                    <p style={{ margin: "0px" }}>{value}</p>
                  ))}
                </div>
              </div>
            ),
          },

          sourcePosition: "right",
          targetPosition: "left",
        };
        setNodes((nds) => [...nds, newNode]);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      } else {
        const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

        const newNodePosition = lastNode
          ? {
            x: lastNode.position.x + 300, // Offset horizontally from the last node
            y: lastNode.position.y, // Offset vertically from the last node
          }
          : { x: 100, y: 100 };

        // if destination type is other than IVR then make normal node(with 1 source node)---->NEWIVR
        const newNode = {
          id: `${NodeId}`,
          type: "NewIVR",
          style: { width: "fit-content" },

          position: newNodePosition,
          destinationType: IvrNodeDestinationType,
          destinationName: getDestinationNameById(IvrNodeDestinationName),
          data: {
            nodeName,
            label: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "250px",
                  rowGap: "6px",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {IvrNodeDestinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type : {IvrNodeDestinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {getDestinationNameById(IvrNodeDestinationName)}
                </p>
                <Handle
                  type="source"
                  position="right"
                  id="sourceHandle"
                  style={{
                    background: "#333333",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
                <Handle
                  type="target"
                  position="left"
                  id="targetHandle"
                  style={{
                    background: "#333333",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    zIndex: 100,
                  }}
                />
              </div>
            ),
            buttonList: selectedNodeType === "button" ? buttonList : [],
          },
          sourcePosition: "right",
          targetPosition: "left",
        };
        setNodes((nds) => [...nds, newNode]);
        setNodeName("");
        setIvrNodeDestinationType("IVR");
        setIvrNodeDestinationName("");
        setApiCurl("");
        setOpenCreateIvrNodeModal(false);
      }
    }
  };

  const handleIvrEditNodeModalButton = () => {
    if (selectedNodeType == "CreatedNewIVR") {
      EditCreatedNewIVR();
      return;
    } else if (selectedNodeType == "NewIVR") {
      EditNewIVR();
      return;
    } else if (selectedNodeType == "IVRNode") {
      EditIVRNode();
      return;
    }
  };

  const EditNewIVR = async () => {
    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await updateIvrOption(
      accessToken,
      domainUUID,
      IvrMenuList,
      IVR_MENU_OPTION_PARAM,
      nodeName,
      IvrNodeDestinationName
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "NewIVR",
        style: { width: "fit-content" },
        position: newNodePosition,
        destinationType: IvrNodeDestinationType,
        destinationName: getDestinationNameById(IvrNodeDestinationName),
        data: {
          nodeName,
          nodeData,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {IvrNodeDestinationType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Type : {IvrNodeDestinationType}
              </p>
              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Destination Name :<br />
                {getDestinationNameById(IvrNodeDestinationName)}
              </p>
              <Handle
                type="source"
                position="right"
                id="sourceHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
              <Handle
                type="target"
                position="left"
                id="targetHandle"
                style={{
                  background: "#333333",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  zIndex: 100,
                }}
              />
            </div>
          ),
          buttonList: selectedNodeType === "button" ? buttonList : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setUpdateMode(false);
      setOpenCreateIvrNodeModal(false);
      setIvrNodeDestinationType("IVR");
      setIvrNodeDestinationName("");
      setApiCurl("");
      setNodeName("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const EditIVRNode = async () => {
    var IVR_MENU_OPTION_PARAM;
    if (IvrNodeDestinationType == "IVR") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML IVR`;
    } else if (IvrNodeDestinationType == "QUEUE") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML callcenter`;
    } else if (IvrNodeDestinationType == "PLAYBACK") {
      const value = getFileLocationById(IvrNodeDestinationName).substring(
        getFileLocationById(IvrNodeDestinationName).lastIndexOf("/") + 1
      );

      IVR_MENU_OPTION_PARAM = `transfer ${value} XML PLAYBACK`;
    } else if (IvrNodeDestinationType == "VOICEMAIL") {
      IVR_MENU_OPTION_PARAM = `transfer ${getVoiceMailIdById(
        IvrNodeDestinationName
      )} XML VOICEMAIL`;
    } else if (IvrNodeDestinationType == "HANGUP") {
      IVR_MENU_OPTION_PARAM = `hangup`;
    } else if (IvrNodeDestinationType == "CALLBACK") {
      IVR_MENU_OPTION_PARAM = `transfer callback XML CALLBACK`;
    } else if (IvrNodeDestinationType == "VOICEBOT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML voicebot`;
    } else if (IvrNodeDestinationType == "AIAGENT") {
      IVR_MENU_OPTION_PARAM = `transfer ${IvrNodeDestinationName} XML ai_agent`;
    }

    const result = await updateIvrOption(
      accessToken,
      domainUUID,
      IvrMenuList,
      IVR_MENU_OPTION_PARAM,
      nodeName,
      IvrNodeDestinationName
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300,
      //       y: lastNode.position.y,
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "IVRNode",
        style: { width: "fit-content" },
        position: newNodePosition,
        destinationType: IvrNodeDestinationType,
        destinationName: getDestinationNameById(IvrNodeDestinationName),

        data: {
          nodeName,
          // nodeData,
          label: (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "89% 10%",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "5px",
                  alignItems: "flex-start",
                }}
              >
                <h2
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                    margin: "0px",
                    textTransform: "capitalize",
                    color: "#094e6c",
                    borderBottom: "1px #006d77 solid ",
                    paddingBottom: "3px",
                  }}
                >
                  <MessageOutlined
                    style={{
                      fontSize: "18px",
                      color: "#006d77",
                      marginRight: "8px",
                    }}
                  />
                  {IvrNodeDestinationType}
                </h2>

                <h3
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    margin: "0px",
                    color: "#161a1d",
                  }}
                >
                  Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
                </h3>

                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Type : {IvrNodeDestinationType}
                </p>
                <p
                  style={{
                    border: "1px lightgrey solid",
                    borderRadius: "4px",
                    padding: "6px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    fontSize: "16px",
                    fontStyle: "italic",
                    backgroundColor: "#E9F6E6",
                    margin: "0px 5px 0px 0px",
                    textAlign: "justify",
                    width: "95%",
                  }}
                >
                  Destination Name :<br />
                  {getDestinationNameById(IvrNodeDestinationName)}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "3px",
                }}
              >
                {[
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  "*",
                  "#",
                ].map((value, index) => (
                  <p style={{ margin: "0px" }}>{value}</p>
                ))}
              </div>
            </div>
          ),
        },

        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setUpdateMode(false);
      setOpenCreateIvrNodeModal(false);
      setIvrNodeDestinationType("IVR");
      setIvrNodeDestinationName("");
      setApiCurl("");
      setNodeName("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const getDetailsOfIVRNode = (id, destinationType) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-ivr-option-menu-details-by-ivr-menu_options_uuid/${configurationId}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIvrMenuList(result.IvrMenuList);

        setIvrNodeDestinationType(destinationType);
        const inputString = result.IvrMenuList[0].ivr_menu_option_param;

        if (inputString.startsWith("transfer ")) {
          const fetchedValue = inputString.split(" ")[1];
          setIvrNodeDestinationName(fetchedValue);
        }
      })
      .catch((error) => console.error(error));
  };

  const getDetailsOfNewIVR = (id, destinationType) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}/get-ivr-option-menu-details-by-ivr-menu_options_uuid/${configurationId}/${id}?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setIvrMenuList(result.IvrMenuList);

        setIvrNodeDestinationType(destinationType);
        const inputString = result.IvrMenuList[0].ivr_menu_option_param;

        if (inputString.startsWith("transfer ")) {
          const fetchedValue = inputString.split(" ")[1];

          if (destinationType == "VOICEMAIL") {
            FetchExtensionList();
            setIvrNodeDestinationName(getVoiceMailIdByName(fetchedValue));
          } else if (destinationType == "PLAYBACK") {
            const trimmedValue = fetchedValue.split(".")[0];
            setIvrNodeDestinationName(trimmedValue);
          } else {
            setIvrNodeDestinationName(fetchedValue);
          }
        } else {
          if (destinationType == "HANGUP") {
            setIvrNodeDestinationName("hangup");
          } else if (destinationType == "CALLBACK") {
            setIvrNodeDestinationName("callback");
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const HandleIvrDeleteNodeModal = (id) => {
    // <-----------to be used later for dynamic deletion ------------->
    // if (selectedNodeType == "CreatedNewIVR") {
    //   DeleteCreatedNewIVR();
    //   return;
    // }
    if (selectedNodeType == "NewIVR") {
      DeleteNewIVR(id);
      return;
    }
    if (selectedNodeType == "IVRNode") {
      DeleteIVRNode(id);
      return;
    }
  };

  // STATES FOR CREATING THE WHATSAPP NODE MODAL
  const [OpenCreateWhatsAppNodeModal, setOpenCreateWhatsAppNodeModal] = useState(false);
  const [WhatsappNodeConditionType, setWhatsappNodeConditionType] = useState("message"); // Default selected menu
  const [ConditionData, setConditionData] = useState("");

  const WhatsappConditionTypeItems = [
    { name: "message", icon: <MarkUnreadChatAlt id="MenuIcon" /> },
    { name: "chat", icon: <QuestionAnswer id="MenuIcon" /> },
    { name: "button", icon: <TouchApp id="MenuIcon" /> },
    { name: "API", icon: <Api id="MenuIcon" /> },
  ];

  const WhatsappApiMethodItems = [
    { name: "Get", value: "GET" },
    { name: "Post", value: "POST" },
    { name: "Put", value: "PUT" },
    { name: "Delete", value: "DELETE" },
  ];

  const HandleWhatsappNodeConditionTypeClick = (type) => {
    setWhatsappNodeConditionType(type);
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };

  const HandleWhatsappCreateNodeModalCancelButton = () => {
    setOpenCreateWhatsAppNodeModal(false);
    setUpdateMode(false);
    setWhatsappNodeConditionType("message");
    setNodeName("");
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };

  const HandleWhatsappCreateNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (WhatsappNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await addChatbotCase(
      accessToken,
      domainUUID,
      WhatsappNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationId,
      queue,
      channel
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const newNodePosition = lastNode
        ? {
          x: lastNode.position.x + 300,
          y: lastNode.position.y,
        }
        : { x: 100, y: 100 };
      const newNode = {
        id: `${NodeId}`,
        type:
          WhatsappNodeConditionType === "button"
            ? "custom"
            : WhatsappNodeConditionType,
        selectedImage: selectedImage,
        style: { width: "fit-content" },
        position: newNodePosition,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {WhatsappNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {WhatsappNodeConditionType === "button" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {WhatsappNodeConditionType != "button" && (
                <>
                  <Handle
                    type="source"
                    position="right"
                    id="sourceHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                  <Handle
                    type="target"
                    position="left"
                    id="targetHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                </>
              )}
            </div>
          ),
          buttonList: WhatsappNodeConditionType === "button" ? buttonList : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateWhatsAppNodeModal(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const HandleWhatsappEditNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (WhatsappNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (WhatsappNodeConditionType == "button" ||
        WhatsappNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await updateChatbotCase(
      domainUUID,
      accessToken,
      selectedNodeId,
      WhatsappNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationId,
      queue,
      channel
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;

      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        selectedImage: selectedImage,
        type:
          WhatsappNodeConditionType === "button"
            ? "custom"
            : WhatsappNodeConditionType,
        style: { width: "fit-content" },
        position: newNodePosition,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {WhatsappNodeConditionType == "custom"
                  ? "button"
                  : WhatsappNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {(WhatsappNodeConditionType === "button" ||
                WhatsappNodeConditionType === "custom") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {WhatsappNodeConditionType !== "button" &&
                WhatsappNodeConditionType !== "custom" && (
                  <>
                    <Handle
                      type="source"
                      position="right"
                      id="sourceHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                    <Handle
                      type="target"
                      position="left"
                      id="targetHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                  </>
                )}
            </div>
          ),
          buttonList:
            WhatsappNodeConditionType === "button" ||
              WhatsappNodeConditionType === "custom"
              ? buttonList
              : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateWhatsAppNodeModal(false);
      setUpdateMode(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  const HandleWhatsappDeleteNodeModal = async (id) => {
    const result = await DeleteChatbotCase(
      domainUUID,
      accessToken,
      id,
      channel
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateWhatsAppNodeModal(false);
      setWhatsappNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // STATES FOR CREATING THE VOICE BOT NODES MODAL
  const [OpenCreateVoicebotNodeModal, setOpenCreateVoicebotNodeModal] =
    useState(false);
  const [VoicebotNodeConditionType, setVoicebotNodeConditionType] =
    useState("message");

  const VoicebotConditionTypeItems = [
    {
      name: "Message",
      icon: <MarkUnreadChatAlt id="MenuIcon" />,
      value: "message",
    },
    {
      name: "Welcome Message",
      icon: <MarkChatRead id="MenuIcon" />,
      value: "welcome_message",
    },
    {
      name: "Connect To Agent",
      icon: <ConnectWithoutContact id="MenuIcon" />,
      value: "connect_agent",
    },
    {
      name: "User Input",
      icon: <RecordVoiceOver id="MenuIcon" />,
      value: "user_input",
    },
    {
      name: "Invalid Message",
      icon: <SmsFailed id="MenuIcon" />,
      value: "invalid",
    },
    {
      name: "Whats-App",
      icon: <WhatsApp id="MenuIcon" />,
      value: "whatsapp",
    },
  ];

  useEffect(() => {
    if (VoicebotNodeConditionType == "connect_agent") {
      showQueueListForIvr();
    }
  }, [VoicebotNodeConditionType]);

  const HandleVoicebotNodeConditionTypeClick = (type) => {
    setVoicebotNodeConditionType(type);
    setConditionData("");
    setVoiceBotLanguage("en-US");
    setVoiceBotVoiceType("female");
    setButtonText("");
    setButtonList([]);
    setVoiceBotUserInput("");
    setQueueForVoiceBot("");
  };

  const HandleVoicebotCreateNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (VoicebotNodeConditionType == "message" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (VoicebotNodeConditionType == "user_input" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    if (
      VoicebotNodeConditionType == "connect_agent" &&
      queueForVoiceBot == ""
    ) {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json, text/plain, */*");
    myHeaders.append("Accept-Language", "en-US,en;q=0.9");
    myHeaders.append("Authorization", `Bearer ${accessToken} `);
    myHeaders.append("Connection", "keep-alive");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      "_hjSessionUser_1932330=eyJpZCI6IjAxMDZlMDIwLWY2MGYtNTNjNy1hMThmLTc5MjA3ODdlNmM1YSIsImNyZWF0ZWQiOjE3MTk5OTc1OTcxOTMsImV4aXN0aW5nIjp0cnVlfQ==; _ga=GA1.1.781975748.1719997597; _ga_TFK6JQZ10J=GS1.1.1719997597.1.1.1719999224.0.0.0; _ga_NEX85DDBLQ=GS1.1.1724569620.7.0.1724569620.0.0.0"
    );

    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append(
      "User-Agent",
      "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36"
    );
    myHeaders.append(
      "sec-ch-ua",
      '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"'
    );
    myHeaders.append("sec-ch-ua-mobile", "?0");
    myHeaders.append("sec-ch-ua-platform", '"Linux"');

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      condition: "",
      condition_type: VoicebotNodeConditionType,
      condition_data: ConditionData,
      language: voiceBotLanguage,
      configuration_uuid: configurationId,
      voice_type: voiceBotVoiceType,
      queue_uuid: queueForVoiceBot,
      user_input_type: voiceBotUserInput,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}/${REACT_APP_API_URL_CASE_CALL_CENTER}//tenant/create-voicebot-case?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          const NodeId = result.NodeId;
          const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

          const newNodePosition = lastNode
            ? {
              x: lastNode.position.x + 400, // Offset horizontally from the last node
              y: lastNode.position.y, // Offset vertically from the last node
            }
            : { x: 100, y: 100 };
          const newNode = {
            id: `${NodeId}`,
            type: "VoiceBotNode",
            style: { width: "fit-content" },
            // position: { x: Math.random() * 200, y: Math.random() * 200 },
            position: newNodePosition,
            name: nodeName,
            conditionType: VoicebotNodeConditionType,
            conditionData: ConditionData,
            UserInput: voiceBotUserInput,
            buttonList: buttonList,
            data: {
              nodeName,
              ConditionData,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />

                    {VoicebotNodeConditionType === "message"
                      ? "Message"
                      : VoicebotNodeConditionType === "welcome_message"
                        ? "Welcome Message"
                        : VoicebotNodeConditionType === "connect_agent"
                          ? "Connect To Agent"
                          : VoicebotNodeConditionType === "user_input"
                            ? "User Input"
                            : "Invalid Message"}
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      // textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Type: {VoicebotNodeConditionType}
                  </p>

                  <p
                    style={{
                      border: "1px lightgrey solid",
                      borderRadius: "4px",
                      padding: "6px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontStyle: "italic",
                      backgroundColor: "#E9F6E6",
                      margin: "0px 5px 0px 0px",
                      textAlign: "justify",
                      width: "95%",
                    }}
                  >
                    Condition Data : {ConditionData}
                  </p>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ),
              buttonList: buttonList,
            },

            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setOpenCreateVoicebotNodeModal(false);
          HandleVoicebotNodeConditionTypeClick("message");
          setConditionData("");
          setNodeName("");
          setVoiceBotLanguage("en-US");
          setVoiceBotVoiceType("female");
          setButtonText("");
          setButtonList([]);
          setVoiceBotUserInput("");
          setQueueForVoiceBot("");
        } else {
          toast.info(result.Msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const HandleVoicebotEditNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required Fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (VoicebotNodeConditionType == "message" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (VoicebotNodeConditionType == "user_input" && voiceBotUserInput == "") {
      toast.info("Please Select user input!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      VoicebotNodeConditionType == "connect_agent" &&
      queueForVoiceBot == ""
    ) {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const result = await EditVoiceBotNode(
      accessToken,
      domainUUID,
      VoicebotNodeConditionType,
      ConditionData,
      voiceBotLanguage,
      configurationId,
      IDforVoiceBot,
      voiceBotVoiceType,
      queueForVoiceBot,
      voiceBotUserInput
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;
      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        type: "VoiceBotNode",
        style: { width: "fit-content" },
        position: newNodePosition,
        name: nodeName,
        conditionType: VoicebotNodeConditionType,
        conditionData: ConditionData,
        buttonList: buttonList,
        UserInput: voiceBotUserInput,
        data: {
          nodeName,
          ConditionData,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {VoicebotNodeConditionType === "message"
                  ? "Message"
                  : VoicebotNodeConditionType === "welcome_message"
                    ? "Welcome Message"
                    : VoicebotNodeConditionType === "connect_agent"
                      ? "Connect To Agent"
                      : VoicebotNodeConditionType === "user_input"
                        ? "User Input"
                        : "Invalid Message"}

                {/* Voice Bot */}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  // textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Type : {VoicebotNodeConditionType}
              </p>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                Condition Data : {ConditionData}
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                {buttonList.map((buttonLabel, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginBottom: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#006d77",
                          borderRadius: "50%",
                          marginRight: "8px",
                        }}
                      />
                      <span>{buttonLabel}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ),
          buttonList: buttonList,
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateVoicebotNodeModal(false);
      HandleVoicebotNodeConditionTypeClick("message");
      setNodeName("");
      setConditionData("");
      setVoiceBotLanguage("en-US");
      setVoiceBotVoiceType("female");
      setButtonText("");
      setButtonList([]);
      setVoiceBotUserInput("");
      setQueueForVoiceBot("");
    }
  };

  const HandleVoicebotCreateNodeModalDeleteButton = async (id) => {
    const result = await DeleteNodeForVoiceBot(
      accessToken,
      domainUUID,
      IDforVoiceBot,
      id
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateVoicebotNodeModal(false);
      HandleVoicebotNodeConditionTypeClick("message");
      setNodeName("");
      setConditionData("");
      setVoiceBotLanguage("en-US");
      setVoiceBotVoiceType("female");
      setButtonText("");
      setButtonList([]);
      setVoiceBotUserInput("");
      setQueueForVoiceBot("");
    }
  };

  const HandleVoicebotCreateNodeModalCancelButton = () => {
    setOpenCreateVoicebotNodeModal(false);
    setUpdateMode(false);
    HandleVoicebotNodeConditionTypeClick("message");
    setNodeName("");
    setConditionData("");
    setVoiceBotLanguage("en-US");
    setVoiceBotVoiceType("female");
    setButtonText("");
    setButtonList([]);
    setVoiceBotUserInput("");
    setQueueForVoiceBot("");
  };

  // STATES FOR CREATING THE NODES FOR SMS

  const [OpenCreateSmsbotNodeModal, setOpenCreateSmsbotNodeModal] =
    useState(false);
  const [SmsbotNodeDestinationType, setSmsbotNodeDestinationType] =
    useState("API");
  const [SmsbotApiCurlName, setSmsbotApiCurlName] = useState("");

  const SmsDestinationTypeItems = [
    { name: "API", icon: <Api id="MenuIcon" /> },
  ];

  const HandleSmsbotNodeDestinationTypeClick = (type) => {
    setSmsbotNodeDestinationType(type);
  };

  const HandleSmsCreateNodeModalCancelButton = () => {
    if (updateMode) {
      setUpdateMode(false);
    }

    setSmsbotNodeDestinationType("API");
    setNodeName("");
    setSmsbotApiCurlName("");
    setOpenCreateSmsbotNodeModal(false);
  };

  const HandleSmsbotEditNodeModalButton = () => {
    // For editing SMS node
  };

  const HandleSmsbotCreateNodeModalButton = () => {
    if (!SmsbotApiCurlName || !nodeName) {
      toast.info("Please Enter the Required Fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("authorization", `Bearer ${accessToken}`);

    const raw = JSON.stringify({
      domain_uuid: domainUUID,
      broadcast_uuid: configurationId,
      condition_type: "api",
      condition_data: SmsbotApiCurlName,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_API_URL}:10601/sms/sms-bot-case/create?user_uuid=${domainUUID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "1") {
          const NodeId = result.node_id;

          const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

          const newNodePosition = lastNode
            ? {
              x: lastNode.position.x + 300, // Offset horizontally from the last node
              y: lastNode.position.y, // Offset vertically from the last node
            }
            : { x: 100, y: 100 };

          const newNode = {
            id: `${NodeId}`,
            // id: `${nodes.length + 10}`,
            type: "SmsNode",
            style: { width: "fit-content" },
            position: newNodePosition,
            Name: nodeName,
            Curl: SmsbotApiCurlName,
            data: {
              nodeName,
              label: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "250px",
                    rowGap: "6px",
                  }}
                >
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px",
                      textTransform: "capitalize",
                      color: "#094e6c",
                      borderBottom: "1px #006d77 solid ",
                      paddingBottom: "3px",
                    }}
                  >
                    <MessageOutlined
                      style={{
                        fontSize: "18px",
                        color: "#006d77",
                        marginRight: "8px",
                      }}
                    />
                    API
                  </h2>

                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      margin: "0px",
                      color: "#161a1d",
                    }}
                  >
                    Name :{" "}
                    <span style={{ fontWeight: "400" }}> {nodeName}</span>
                  </h3>

                  <Handle
                    type="source"
                    position="right"
                    id="sourceHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                  <Handle
                    type="target"
                    position="left"
                    id="targetHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                </div>
              ),
            },
            sourcePosition: "right",
            targetPosition: "left",
          };
          setNodes((nds) => [...nds, newNode]);
          setNodeName("");
          setSmsbotApiCurlName("");
          setOpenCreateSmsbotNodeModal(false);
        } else {
          toast.info(result.msg, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      })
      .catch((error) => console.error(error));

    // For creating the  SMS node
  };

  const HandleSmsbotDeleteNodeModal = () => {
    // For deleting the  SMS node
  };

  const onConnectEnd = () => {
    handleModalForAddNode()
  }

  // STATES FOR CREATING THE TELEGRAM NODES MODAL
  const [OpenCreateTelegramNodeModal, setOpenCreateTelegramNodeModal] = useState(false);
  const [TelegramNodeConditionType, setTelegramNodeConditionType] = useState("message"); // Default selected menu

  const TelegramConditionTypeItems = [
    { name: "message", icon: <MarkUnreadChatAlt id="MenuIcon" /> },
    { name: "chat", icon: <QuestionAnswer id="MenuIcon" /> },
    { name: "button", icon: <TouchApp id="MenuIcon" /> },
    { name: "API", icon: <Api id="MenuIcon" /> },
  ];

  const TelegramApiMethodItems = [
    { name: "Get", value: "GET" },
    { name: "Post", value: "POST" },
    { name: "Put", value: "PUT" },
    { name: "Delete", value: "DELETE" },
  ];

  const HandleTelegramNodeConditionTypeClick = (type) => {
    setTelegramNodeConditionType(type);
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  };


  const HandleTelegramCreateNodeModalCancelButton = () => {
    setOpenCreateTelegramNodeModal(false);
    setUpdateMode(false);
    setTelegramNodeConditionType("message");
    setNodeName("");
    setConditionData("");
    setQueue("");
    setSelectedImage("");
    setButtonList([]);
    setButtonText("");
    setConditionAPI("");
    setPayload("");
    setMethod("");
    setPayloadType("");
  }

  const HandleTelegramCreateNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (TelegramNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await addChatbotCase(
      accessToken,
      domainUUID,
      TelegramNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationName,
      queue,
      channel
    );

    if (result.Status == "1") {
      const NodeId = result.NodeId;
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      const newNodePosition = lastNode
        ? {
          x: lastNode.position.x + 300,
          y: lastNode.position.y,
        }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${NodeId}`,
        type:
          TelegramNodeConditionType === "button"
            ? "custom"
            : TelegramNodeConditionType,
        selectedImage: selectedImage,
        style: { width: "fit-content" },
        position: newNodePosition,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />
                {TelegramNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name :<span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {TelegramNodeConditionType === "button" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {buttonList.map((buttonLabel, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "4px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: "#006d77",
                            borderRadius: "50%",
                            marginRight: "8px",
                          }}
                        />
                        <span>{buttonLabel}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {TelegramNodeConditionType != "button" && (
                <>
                  <Handle
                    type="source"
                    position="right"
                    id="sourceHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                  <Handle
                    type="target"
                    position="left"
                    id="targetHandle"
                    style={{
                      background: "#333333",
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      zIndex: 100,
                    }}
                  />
                </>
              )}
            </div>
          ),
          buttonList: TelegramNodeConditionType === "button" ? buttonList : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateTelegramNodeModal(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  }

  const HandleTelegramEditNodeModalButton = async () => {
    if (nodeName == "" || ConditionData == "") {
      toast.info("Please Enter the Required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (TelegramNodeConditionType == "chat" && queue == "") {
      toast.info("Please Select Queue!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage == ""
    ) {
      toast.info("Please select one layout!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    if (
      (TelegramNodeConditionType == "button" ||
        TelegramNodeConditionType == "custom") &&
      selectedImage != "" &&
      buttonList.length == 0
    ) {
      toast.info("Please add atleast 1 button!", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const convertedButtonList = JSON.stringify(
      buttonList.map((item) => [item])
    );

    const result = await updateChatbotCase(
      domainUUID,
      accessToken,
      selectedNodeId,
      TelegramNodeConditionType,
      ConditionData,
      conditionAPI,
      method,
      payloadType,
      payload,
      convertedButtonList,
      selectedImage,
      configurationName,
      queue,
      channel
    );

    if (result.Status == "1") {
      const lastNode = nodes.length > 0 ? nodes[nodes.length - 1] : null;

      // const newNodePosition = lastNode
      //   ? {
      //       x: lastNode.position.x + 300, // Offset horizontally from the last node
      //       y: lastNode.position.y, // Offset vertically from the last node
      //     }
      //   : { x: 100, y: 100 };

      const nodeIdToFind = selectedNodeId;

      const existingNode = nodes.find((node) => node.id === nodeIdToFind);

      const newNodePosition = existingNode
        ? { ...existingNode.position }
        : { x: 100, y: 100 };

      const newNode = {
        id: `${selectedNodeId}`,
        selectedImage: selectedImage,
        type:
          TelegramNodeConditionType === "button"
            ? "custom"
            : TelegramNodeConditionType,
        style: { width: "fit-content" },
        position: newNodePosition,
        data: {
          nodeName,
          ConditionData,
          queue,
          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "250px",
                rowGap: "6px",
              }}
            >
              <h2
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px",
                  textTransform: "capitalize",
                  color: "#094e6c",
                  borderBottom: "1px #006d77 solid ",
                  paddingBottom: "3px",
                }}
              >
                <MessageOutlined
                  style={{
                    fontSize: "18px",
                    color: "#006d77",
                    marginRight: "8px",
                  }}
                />

                {TelegramNodeConditionType == "custom"
                  ? "button"
                  : TelegramNodeConditionType}
              </h2>

              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "0px",
                  color: "#161a1d",
                }}
              >
                Name : <span style={{ fontWeight: "400" }}> {nodeName}</span>
              </h3>

              <p
                style={{
                  border: "1px lightgrey solid",
                  borderRadius: "4px",
                  padding: "6px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontStyle: "italic",
                  backgroundColor: "#E9F6E6",
                  margin: "0px 5px 0px 0px",
                  textAlign: "justify",
                  width: "95%",
                }}
              >
                {ConditionData}
              </p>

              {(TelegramNodeConditionType === "button" ||
                TelegramNodeConditionType === "custom") && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "10px",
                    }}
                  >
                    {buttonList.map((buttonLabel, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          marginBottom: "4px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "#006d77",
                              borderRadius: "50%",
                              marginRight: "8px",
                            }}
                          />
                          <span>{buttonLabel}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

              {TelegramNodeConditionType !== "button" &&
                TelegramNodeConditionType !== "custom" && (
                  <>
                    <Handle
                      type="source"
                      position="right"
                      id="sourceHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                    <Handle
                      type="target"
                      position="left"
                      id="targetHandle"
                      style={{
                        background: "#333333",
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        zIndex: 100,
                      }}
                    />
                  </>
                )}
            </div>
          ),
          buttonList:
            TelegramNodeConditionType === "button" ||
              TelegramNodeConditionType === "custom"
              ? buttonList
              : [],
        },
        sourcePosition: "right",
        targetPosition: "left",
      };
      setNodes((nds) => [...nds, newNode]);
      setOpenCreateTelegramNodeModal(false);
      setUpdateMode(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }

  const HandleTelegramDeleteNodeModal = async (id) => {
    const result = await DeleteChatbotCase(
      domainUUID,
      accessToken,
      id,
      channel
    );

    if (result.Status == "1") {
      setNodes((nds) => nds.filter((node) => node.id !== id));
      setEdges((eds) =>
        eds.filter((edge) => edge.source !== id && edge.target !== id)
      );
      setUpdateMode(false);
      setOpenCreateTelegramNodeModal(false);
      setTelegramNodeConditionType("message");
      setNodeName("");
      setConditionData("");
      setQueue("");
      setSelectedImage("");
      setButtonList([]);
      setButtonText("");
      setConditionAPI("");
      setPayload("");
      setMethod("");
      setPayloadType("");
    } else {
      toast.info(result.Msg, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  }

  // This is for fetching  the Queueslist for Omnichannel 
  useEffect(() => {
    if (WhatsappNodeConditionType == "chat" || TelegramNodeConditionType == "chat") {
      fetchQueuesList();
    }

  }, [WhatsappNodeConditionType, TelegramNodeConditionType]);


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <div
          style={{
            background: "#f0f0f0",
            border: "1px solid #ddd",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              padding: '5px 0px'
            }}
          >
            <Tooltip title="Back to previous page" placement="top-end">
              <KeyboardBackspace
                id="BackButton"
                onClick={() => {
                  navigate("/all_flows", { state: { channel } });
                }}
              />
            </Tooltip>

            <h3
              style={{
                color: "#6b6f82",
                fontWeight: "500",
                fontSize: "22px",
                margin: '0px'
              }}
            >
              {channel === "voiceBot" && <>Voice Bot</>}
              {channel === "whatsapp" && <>WhatsApp Bot</>}
              {channel === "telegram" && <>Telegram Bot</>}
              {channel === "viber" && <>Viber Bot</>}
              {channel === "IVR" && <>IVR</>}
              {channel === "smsBroadcast" && <>SMS Broadcast</>}

              <span style={{ color: "#746df7" }}> Channel</span>
            </h3>

            <h2
              style={{
                fontSize: "33px",
                fontWeight: "300",
                color: "grey",
                margin: '0px'
              }}
            >
              {" "}
              |{" "}
            </h2>

            <p
              style={{
                fontWeight: "500",
                fontSize: "16px",
                margin: '0px'
              }}
            >
              Configuration Name :
              <span
                style={{
                  textTransform: "capitalize",
                  color: "#746df7",
                  marginLeft: "4px",
                }}
              >
                {configurationName}
              </span>
            </p>
          </div>

          <div>
            <Button
              id="AddNodeButton"
              onClick={() => {
                handleModalForAddNode();
              }}
              style={{ margin: "0px 10px" }}
            >
              Add Node
            </Button>

            <Button
              style={{
                margin: "0px 10px",
              }}
              id="AddNodeButton"
              onClick={PublishChanges}
            >
              Publish Changes
            </Button>
          </div>
        </div>

        <ReactFlowProvider>
          <ReactFlow
            nodeTypes={nodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onNodeClick={handleNodeClick}
            onReconnect={onReconnect}
            onReconnectStart={onReconnectStart}
            onReconnectEnd={onReconnectEnd}
            deleteKeyCode={46}
            proOptions={{ hideAttribution: true }}
            fitView
          >
            <MiniMap
              maskColor="white"
              maskStrokeWidth={1}
              style={{ backgroundColor: "white", border: "2px solid grey" }}
            />
            <Controls />
            <Background variant={backgroundVariant} />
          </ReactFlow>
        </ReactFlowProvider>

        {/* Save & restore layout buttons  */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "10px",
          }}
        >
          <FormControl style={{ margin: "10px", minWidth: 200 }}>
            <InputLabel id="background-variant-label">Background</InputLabel>
            <Select
              labelId="background-variant-label"
              value={backgroundVariant}
              onChange={handleVariantChange}
              label="Background"
              sx={{
                height: "35px",
                fontSize: "14px",
              }}
            >
              <MenuItem value="dots">Dots</MenuItem>
              <MenuItem value="cross">Cross</MenuItem>
              <MenuItem value="lines">Lines</MenuItem>
            </Select>
          </FormControl>

          <div>
            <Button
              onClick={handleSaveLayout}
              style={{ margin: "10px", minWidth: 180 }}
              id="AddNodeButton"
            >
              Save layout
            </Button>

            <Button
              id="AddNodeButton"
              onClick={handleRestoreLayout}
              style={{ margin: "10px", minWidth: 180 }}
            >
              Restore layout
            </Button>
          </div>
        </div>
      </div>


      {/* MODAL FOR CREATING THE NODES FOR IVR*/}
      <Modal
        open={OpenCreateIvrNodeModal}
        onClose={() => HandleIvrCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> IVR Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter the node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Destination Type </h2>
              {IvrDestinationTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${IvrNodeDestinationType === type.name ? "active" : ""
                    }`}
                  onClick={() => HandleIvrNodeDestinationTypeClick(type.name)}
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Menu Content with Submenus */}
            <div className="MenuContentBody">
              <h2>
                {IvrNodeDestinationType != "API"
                  ? "Select one destination name from below"
                  : ""}
              </h2>

              {IvrNodeDestinationType == "API" ? (
                <div id="NodeNameBox" style={{ marginTop: "20px" }}>
                  <p>Enter the Curl here : </p>
                  <TextField
                    placeholder="Enter name here"
                    variant="outlined"
                    value={apicurl}
                    onChange={(e) => setApiCurl(e.target.value)}
                    fullWidth
                  />
                </div>
              ) : (
                <div className="MenuContent">
                  {destinationNameList
                    ? destinationNameList.map((name, index) => {
                      const id =
                        name.ivr_menu_uuid ||
                        name.call_center_queue_uuid ||
                        name.pmt_uuid ||
                        name.extension_uuid ||
                        name.HangupId ||
                        name.CallbackId ||
                        name.configuration_uuid ||
                        name.knowledge_base_uuid;
                      const displayName =
                        name.ivr_menu_name ||
                        name.queue_name ||
                        name.prompt_name ||
                        name.extension ||
                        name.HangupName ||
                        name.CallbackName ||
                        name.configuration_name ||
                        name.doc_name;

                      return (
                        <div
                          key={index}
                          className={`SubMenuItem ${IvrNodeDestinationName === id ? "selected" : ""
                            }`}
                          onClick={() =>
                            HandleIvrNodeDestinationNameClick(id)
                          }
                        >
                          <NearMe id="Icon" />
                          {displayName}
                        </div>
                      );
                    })
                    : "No data"}
                </div>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleIvrCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? handleIvrEditNodeModalButton()
                  : HandleIvrCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleIvrDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR WHATSAPP*/}
      <Modal
        open={OpenCreateWhatsAppNodeModal}
        onClose={() => HandleWhatsappCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> Whatsapp Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {WhatsappConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${WhatsappNodeConditionType === type.name ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleWhatsappNodeConditionTypeClick(type.name)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {WhatsappNodeConditionType == "message" &&
                  "Message condition type"}
                {WhatsappNodeConditionType == "chat" && "Chat condition type"}
                {WhatsappNodeConditionType == "button" &&
                  "Button condition type"}
                {WhatsappNodeConditionType == "API" && "API condition type"}
              </h2>

              <div id="NodeNameBox">
                <p>Enter the condition data : </p>
                <TextField
                  placeholder="Enter data here"
                  variant="outlined"
                  value={ConditionData}
                  onChange={(e) => setConditionData(e.target.value)}
                  fullWidth
                />
              </div>

              {WhatsappNodeConditionType == "chat" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one Queue from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {queueList.length >= 1
                        ? queueList.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className={`SubMenuItem ${queue === data.queue_uuid ? "selected" : ""
                                }`}
                              onClick={() => setQueue(data.queue_uuid)}
                            >
                              <NearMe id="Icon" />
                              {data.name}
                            </div>
                          );
                        })
                        : "No data"}
                    </div>
                  </div>
                </>
              )}

              {WhatsappNodeConditionType == "button" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select buttons layout design :{" "}
                    </p>

                    <div className="MenuContent">
                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_1"
                            checked={selectedImage === "layout_1"}
                            onChange={() => setSelectedImage("layout_1")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout.jpg")
                                  : null
                            }
                            alt="WhatsApp Layout 1"
                            style={{ width: "220px", height: "100px" }}
                          />
                        </div>
                      </label>

                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_2"
                            checked={selectedImage === "layout_2"}
                            onChange={() => setSelectedImage("layout_2")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout2.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout_2.png")
                                  : null
                            }
                            alt="WhatsApp Layout 2"
                            style={{ width: "220px", height: "150px" }}
                          />
                        </div>
                      </label>
                    </div>

                    {selectedImage != "" && (
                      <>
                        <div
                          id="NodeNameBox"
                          style={{
                            gridTemplateColumns: "24% 45%  20%",
                            columnGap: "12px",
                          }}
                        >
                          <p>Enter names for buttons : </p>
                          <TextField
                            placeholder="Enter button"
                            variant="outlined"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          />

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddButton}
                          >
                            Add Button
                          </Button>
                        </div>

                        <div
                          style={{
                            padding: "10px 40px",
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {buttonList.map((label, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "2px lightgrey solid",
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {label}
                              <Tooltip title="Delete" placement="bottom">
                                <IconButton
                                  color="error"
                                  onClick={() => handleDeleteButton(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {WhatsappNodeConditionType == "API" && (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition API : </p>
                    <TextField
                      placeholder="Enter condition here"
                      variant="outlined"
                      value={conditionAPI}
                      onChange={(e) => setConditionAPI(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div id="NodeNameBox">
                    <p>Enter the payload : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={payload}
                      onChange={(e) => setPayload(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one API method from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {WhatsappApiMethodItems.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className={`SubMenuItem ${method === data.value ? "selected" : ""
                              }`}
                            onClick={() => setMethod(data.value)}
                          >
                            <NearMe id="Icon" />
                            {data.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select payload type :{" "}
                    </p>
                    <div className="MenuContent">
                      <div
                        onClick={() => setPayloadType("query_param")}
                        className={`SubMenuItem ${payloadType == "query_param" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Query param
                      </div>

                      <div
                        onClick={() => setPayloadType("request_body")}
                        className={`SubMenuItem ${payloadType == "request_body" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Request body
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => HandleWhatsappCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleWhatsappEditNodeModalButton()
                  : HandleWhatsappCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node " : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleWhatsappDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR TELEGRAM */}
      <Modal
        open={OpenCreateTelegramNodeModal}
      // onClose={() => HandleWhatsappCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            {updateMode ? "Edit " : "Create"}
            <span> Telegram Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {TelegramConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${TelegramNodeConditionType === type.name ? "active" : ""}`}
                  onClick={() => HandleTelegramNodeConditionTypeClick(type.name)}
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {TelegramNodeConditionType == "message" && "Message condition type"}
                {TelegramNodeConditionType == "chat" && "Chat condition type"}
                {TelegramNodeConditionType == "button" && "Button condition type"}
                {TelegramNodeConditionType == "API" && "API condition type"}
              </h2>

              <div id="NodeNameBox">
                <p>Enter the condition data : </p>
                <TextField
                  placeholder="Enter data here"
                  variant="outlined"
                  value={ConditionData}
                  onChange={(e) => setConditionData(e.target.value)}
                  fullWidth
                />
              </div>

              {TelegramNodeConditionType == "chat" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one Queue from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {queueList.length >= 1
                        ? queueList.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className={`SubMenuItem ${queue === data.queue_uuid ? "selected" : ""
                                }`}
                              onClick={() => setQueue(data.queue_uuid)}
                            >
                              <NearMe id="Icon" />
                              {data.name}
                            </div>
                          );
                        })
                        : "No data"}
                    </div>
                  </div>
                </>
              )}

              {TelegramNodeConditionType == "button" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select buttons layout design :{" "}
                    </p>

                    <div className="MenuContent">
                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_1"
                            checked={selectedImage === "layout_1"}
                            onChange={() => setSelectedImage("layout_1")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout_2.png")
                                  : null
                            }
                            alt="WhatsApp Layout 1"
                            style={{ width: "220px", height: "100px" }}
                          />
                        </div>
                      </label>

                      <label>
                        <div
                          style={{
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            columnGap: "5px",
                          }}
                        >
                          <input
                            type="radio"
                            value="layout_2"
                            checked={selectedImage === "layout_2"}
                            onChange={() => setSelectedImage("layout_2")}
                          />
                          <img
                            src={
                              channel === "whatsapp"
                                ? require("./assets/WhatsApplayout2.jpg")
                                : channel === "telegram"
                                  ? require("./assets/telegram_layout.jpg")
                                  : null
                            }
                            alt="WhatsApp Layout 2"
                            style={{ width: "220px", height: "150px" }}
                          />
                        </div>
                      </label>
                    </div>

                    {selectedImage != "" && (
                      <>
                        <div
                          id="NodeNameBox"
                          style={{
                            gridTemplateColumns: "24% 45%  20%",
                            columnGap: "12px",
                          }}
                        >
                          <p>Enter names for buttons : </p>
                          <TextField
                            placeholder="Enter button"
                            variant="outlined"
                            value={buttonText}
                            onChange={(e) => setButtonText(e.target.value)}
                            fullWidth
                            style={{ marginBottom: "10px", marginTop: "10px" }}
                          />

                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAddButton}
                          >
                            Add Button
                          </Button>
                        </div>

                        <div
                          style={{
                            padding: "10px 40px",
                            display: "flex",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {buttonList.map((label, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "2px lightgrey solid",
                                borderRadius: "10px",
                                paddingLeft: "10px",
                                textTransform: "capitalize",
                              }}
                            >
                              {label}
                              <Tooltip title="Delete" placement="bottom">
                                <IconButton
                                  color="error"
                                  onClick={() => handleDeleteButton(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}

              {TelegramNodeConditionType == "API" && (
                <>
                  <div id="NodeNameBox">
                    <p>Enter the condition API : </p>
                    <TextField
                      placeholder="Enter condition here"
                      variant="outlined"
                      value={conditionAPI}
                      onChange={(e) => setConditionAPI(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div id="NodeNameBox">
                    <p>Enter the payload : </p>
                    <TextField
                      placeholder="Enter here"
                      variant="outlined"
                      value={payload}
                      onChange={(e) => setPayload(e.target.value)}
                      fullWidth
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select one API method from the following :{" "}
                    </p>
                    <div className="MenuContent">
                      {TelegramApiMethodItems.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className={`SubMenuItem ${method === data.value ? "selected" : ""
                              }`}
                            onClick={() => setMethod(data.value)}
                          >
                            <NearMe id="Icon" />
                            {data.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                      rowGap: "8px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "30px",
                        fontWeight: "500",
                        textDecoration: "underline",
                      }}
                    >
                      Select payload type :{" "}
                    </p>
                    <div className="MenuContent">
                      <div
                        onClick={() => setPayloadType("query_param")}
                        className={`SubMenuItem ${payloadType == "query_param" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Query param
                      </div>

                      <div
                        onClick={() => setPayloadType("request_body")}
                        className={`SubMenuItem ${payloadType == "request_body" ? "selected" : ""
                          }`}
                      >
                        <NearMe id="Icon" />
                        Request body
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleTelegramCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleTelegramEditNodeModalButton()
                  : HandleTelegramCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node " : "Add Node"}
            </Button>

            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleTelegramDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/* MODAL FOR CREATING THE NODES FOR VOICE BOT*/}
      <Modal
        open={OpenCreateVoicebotNodeModal}
        onClose={() => HandleVoicebotCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            Create<span> Voice Bot Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter a node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Condition Type </h2>
              {VoicebotConditionTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${VoicebotNodeConditionType === type.value ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleVoicebotNodeConditionTypeClick(type.value)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>
                {VoicebotNodeConditionType == "message" &&
                  "Condition type - Message"}
                {VoicebotNodeConditionType == "welcome_message" &&
                  "Condition type - Welcome message"}
                {VoicebotNodeConditionType == "connect_agent" &&
                  "Condition type - Connect to agent"}
                {VoicebotNodeConditionType == "user_input" &&
                  "Condition type - User input"}
                {VoicebotNodeConditionType == "invalid" &&
                  "Condition type - Invalid Message"}
                {VoicebotNodeConditionType == "whatsapp" &&
                  "Condition type - Whats-App Message"}
              </h2>

              {
                VoicebotNodeConditionType == "whatsapp" ?
                  <>
                    This is for the whatsapp
                  </>
                  :
                  <>
                    <div id="NodeNameBox">
                      <p>Enter the condition data : </p>
                      <TextField
                        placeholder="Enter data here"
                        variant="outlined"
                        value={ConditionData}
                        onChange={(e) => setConditionData(e.target.value)}
                        fullWidth
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "15px",
                        rowGap: "8px",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "30px",
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                      >
                        Select a language :{" "}
                      </p>
                      <div className="MenuContent">
                        <div
                          onClick={() => setVoiceBotLanguage("en-US")}
                          className={`SubMenuItem ${voiceBotLanguage == "en-US" ? "selected" : ""
                            }`}
                        >
                          <NearMe id="Icon" />
                          English
                        </div>

                        <div
                          onClick={() => setVoiceBotLanguage("hi-IN")}
                          className={`SubMenuItem ${voiceBotLanguage == "hi-IN" ? "selected" : ""
                            }`}
                        >
                          <NearMe id="Icon" />
                          Hindi
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "15px",
                        rowGap: "8px",
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "30px",
                          fontWeight: "500",
                          textDecoration: "underline",
                        }}
                      >
                        Select a voice type :{" "}
                      </p>
                      <div className="MenuContent">
                        <div
                          onClick={() => setVoiceBotVoiceType("male")}
                          className={`SubMenuItem ${voiceBotVoiceType == "male" ? "selected" : ""
                            }`}
                        >
                          <NearMe id="Icon" />
                          Male
                        </div>

                        <div
                          onClick={() => setVoiceBotVoiceType("female")}
                          className={`SubMenuItem ${voiceBotVoiceType == "female" ? "selected" : ""
                            }`}
                        >
                          <NearMe id="Icon" />
                          Female
                        </div>
                      </div>
                    </div>

                    <div
                      id="NodeNameBox"
                      style={{
                        gridTemplateColumns: "24% 45%  20%",
                        columnGap: "12px",
                      }}
                    >
                      <p>Enter the options : </p>
                      <TextField
                        placeholder="Enter here"
                        variant="outlined"
                        value={buttonText}
                        onChange={(e) => setButtonText(e.target.value)}
                        fullWidth
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      />

                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddButton}
                      >
                        Add Options
                      </Button>
                    </div>

                    <div
                      style={{
                        padding: "10px 40px",
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {buttonList.map((label, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: "2px lightgrey solid",
                            borderRadius: "10px",
                            paddingLeft: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          {label}
                          <Tooltip title="Delete" placement="bottom">
                            <IconButton
                              color="error"
                              onClick={() => handleDeleteButton(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ))}
                    </div>

                    {(VoicebotNodeConditionType == "message" ||
                      VoicebotNodeConditionType == "user_input") && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "15px",
                            rowGap: "8px",
                          }}
                        >
                          <p
                            style={{
                              marginLeft: "30px",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            Select user input :{" "}
                          </p>
                          <div className="MenuContent">
                            <div
                              onClick={() => setVoiceBotUserInput("true")}
                              className={`SubMenuItem ${voiceBotUserInput == "true" ? "selected" : ""
                                }`}
                            >
                              <NearMe id="Icon" />
                              Yes
                            </div>

                            <div
                              onClick={() => setVoiceBotUserInput("false")}
                              className={`SubMenuItem ${voiceBotUserInput == "false" ? "selected" : ""
                                }`}
                            >
                              <NearMe id="Icon" />
                              No
                            </div>
                          </div>
                        </div>
                      )}

                    {VoicebotNodeConditionType == "connect_agent" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "20px",
                            rowGap: "8px",
                          }}
                        >
                          <p
                            style={{
                              marginLeft: "30px",
                              fontWeight: "500",
                              textDecoration: "underline",
                            }}
                          >
                            Select one Queue from the following :{" "}
                          </p>
                          <div className="MenuContent">
                            {queueListforVoiceBot
                              ? queueListforVoiceBot.map((data, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`SubMenuItem ${queueForVoiceBot ===
                                      data.call_center_queue_uuid
                                      ? "selected"
                                      : ""
                                      }`}
                                    onClick={() =>
                                      setQueueForVoiceBot(
                                        data.call_center_queue_uuid
                                      )
                                    }
                                  >
                                    <NearMe id="Icon" />
                                    {data.queue_name}
                                  </div>
                                );
                              })
                              : "No data"}
                          </div>
                        </div>
                      </>
                    )}
                  </>
              }
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => {
                HandleVoicebotCreateNodeModalCancelButton();
              }}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleVoicebotEditNodeModalButton()
                  : HandleVoicebotCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>
            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => {
                  HandleVoicebotCreateNodeModalDeleteButton(selectedNodeId);
                }}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>

      {/*MODAL FOR CREATING THE NODES FOR SMS */}
      <Modal
        open={OpenCreateSmsbotNodeModal}
        onClose={() => HandleSmsCreateNodeModalCancelButton()}
      >
        <Box sx={{ ...CreateNodeModalStyle }} id="CreateNodeModal">
          <h3 id="CreateNodeHeading">
            Create <span> SMS Node</span>
          </h3>

          <div id="NodeNameBox">
            <p>Enter the node name : </p>
            <TextField
              placeholder="Enter name here"
              variant="outlined"
              value={nodeName}
              onChange={(e) => setNodeName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="Container">
            <div className="TabMenus">
              <h2>Destination Type </h2>
              {SmsDestinationTypeItems.map((type, index) => (
                <div
                  key={index}
                  className={`MenuCard ${SmsbotNodeDestinationType === type.name ? "active" : ""
                    }`}
                  onClick={() =>
                    HandleSmsbotNodeDestinationTypeClick(type.name)
                  }
                >
                  {type.icon}
                  <div id="MenuCardText">
                    <h3>{type.name}</h3>
                    <p>Description</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="MenuContentBody">
              <h2>Destination type : API</h2>

              <div id="NodeNameBox" style={{ marginTop: "20px" }}>
                <p>Enter the Curl here : </p>
                <TextField
                  placeholder="Enter name here"
                  variant="outlined"
                  value={SmsbotApiCurlName}
                  onChange={(e) => setSmsbotApiCurlName(e.target.value)}
                  fullWidth
                />
              </div>
            </div>
          </div>

          <div className="MenuButtons">
            <Button
              variant="outlined"
              id="CancelButton"
              onClick={() => HandleSmsCreateNodeModalCancelButton()}
            >
              Cancel
            </Button>

            <Button
              id="CreateNodeButton"
              onClick={() => {
                updateMode
                  ? HandleSmsbotEditNodeModalButton()
                  : HandleSmsbotCreateNodeModalButton();
              }}
            >
              {updateMode ? "Update Node" : "Add Node"}
            </Button>

            {updateMode && (
              <Button
                variant="contained"
                id="DeleteButton"
                onClick={() => HandleSmsbotDeleteNodeModal(selectedNodeId)}
              >
                Delete
              </Button>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default WorkflowBuilder;
